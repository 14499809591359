import React from 'react'
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined';
const TermsofServices = () => {
  return (
    <div>
         <div className='contact_us_heading d-flex justify-content-center'>
            <DesignServicesOutlinedIcon className='about_detail_icon '/><h3 className='fmBanks_main_heading'>Terms of Service</h3>
      </div>
      <div className="services_content">
         <p className="services_content_typography">1. <b>Travel Channel International (Pvt.)</b> Limited is a private limited company incorporated under the laws of Islamic Republic of Pakistan ('Company'), with its brand name Faremakers.com.</p>
         <p className="services_content_typography">2. The website standard terms of use ('Terms of use') contained herein on this webpage and terms & conditions mentioned ("Terms & Conditions") collectively referred to as "Terms", shall govern your use of this website, including all the pages within this website ('Website'). These Terms, as may be amended from time to time, apply in full force and effect to your use of this Website. By accessing, browsing and using this Website and / or completing a reservation or making an online payment, you unconditionally and irrevocably accept and agree to these Terms governing your use of the Website ("Agreement").</p>

         <p className="services_content_typography">3. This Website contains material which is owned by or licensed and or copyrighted in favor of the Company. This material includes, but is not limited to, the design, layout, look, appearance and graphics and reproduction is prohibited.</p>

         <p className="services_content_typography">4. Our website provides an online forum through which all types of (i) accommodations (for example hotels, motels, hostels and bed & breakfasts), and (ii) international and domestic airline tickets, all will be available for reservations and booking of visitors. By making a reservation or booking through this, you enter into a direct (legally binding) contractual relationship with the accommodation / or airline which you book. From the point at which you make your reservation or booking, we act solely as an intermediary between you and the provider, transmitting the details of your reservation to the relevant accommodation provider and airline ticket provider and sending you a confirmation email for and on behalf of the accommodation or airline ticket provider.</p>

         <p className="services_content_typography">5. Airlines, accommodations, accommodation wholesalers, airline consolidators tour operators ('Service Providers') might also provide terms and conditions that govern particular features, offers or the operating rules and policies applicable to different types of services (for e.g. flights, hotel, reservations, packages, etc.) You are responsible for ensuring compliance with the terms and conditions, guidelines, rules and regulations of every Service Provider. We are not responsible of any information provided to you by the Service Provider -or any other representations and warranties made by the Service Provider.</p>

         <p className="services_content_typography">
6. We will act as an intermediary between you and the Service Provider; therefore, we shall not be liable for any liability that might arise directly or indirectly in respect of any arrangement (s) between you and the Service Provider.</p>

         <p className="services_content_typography">7. You represent and warrant that you possess the legal right, authority, power and capacity to enter into this Agreement and you have carefully read and understood the content of this Agreement.</p>

         <p className="services_content_typography">8. By using this Website you agree to the Terms and are bound by the same. You will be responsible / liable for all payments due to us for reservations or bookings made by you or any other person using your information on your behalf.</p>

         <p className="services_content_typography">9. The Website reserves the right to change its Terms at any time without giving prior written notice. Any changes made will have an immediate effect unless expressly provided for otherwise. It is your responsibility to make sure you have read the most up to date Terms.</p>

         <p className="services_content_typography">10. By accepting the Terms stated herein you hereby accept and acknowledge that you (i) have not been involved in any criminal and/or fraudulent activity (ies) whatsoever currently and in the past and you have never been charged of any criminal offence in the past; (ii) have no link or connection with any terrorist organization.</p>

         <p className="services_content_typography">11. The information provided on the Website is 'as is' and the Company makes no express or implied representations or warranties, of any kind whatsoever in relation to this Website, or the material contained on this Website. We hereby disclaim all warranties and conditions with regard to the Website including all implied warranties and conditions of merchantability, fitness for a particular purpose, title and non-infringement.</p>

         <p className="services_content_typography">12. The Company does not make any express or implied warranties and representations with respect to any risk(s) associated with the destinations selected by you. We shall not be liable for any damages or losses that might incur to you directly or indirectly as a result of traveling to such destinations.</p>

         <p className="services_content_typography">13. The information published on this Website might be incomplete and/or inaccurate. The Company does not represent or warrant that access to the Website will be uninterrupted or that there will be no failures, errors or omissions or loss of transmitted information, or that no viruses will be transmitted on or through this Website. Furthermore, the Company does not warrant or represent that your access to the Website will be free of bugs, viruses, or any other harmful components that might cause unintended damage to the user's computer system.</p>

         <p className="services_content_typography">14. Services provided through our Website for personal and non-commercial use only. Therefore, you are not allowed to re-sell, deep-link, use, copy, or reproduce any content or information, software, products or services available on our website for any commercial or competitive activity or purpose.</p>

         <p className="services_content_typography">15. In no event shall the Company will be liable for any direct, indirect, incidental, special or consequential damages arising out of or in any way connected with the use of this Website or with the delay or inability to use this Website, or for any information, and services obtained through this Website, or otherwise arising out of the use of this Website, whether based on contract, tort or strict liability or otherwise.</p>

         <p className="services_content_typography">16. By purchasing tickets from our Website you hereby agree to provide the Company your Internet Protocol (IP) address, which might be utilized by the Company anytime to trace/locate/find the user in an event of occurrence of any fraudulent/terrorist activity by the user having a detrimental/injurious/damaging affect on the Company.</p>

         <p className="services_content_typography">
17. The Company may get your tickets and products issued from other suppliers to fulfill your travel needs.</p>

         <p className="services_content_typography">18. You assure, represent and warrant that you will use this Website to make only legitimate reservations i.e. you have genuine and valid passport issued by the relevant government authority and valid visa issued by the relevant embassy or consulate for booking an international air ticket. You can book online for up to 6 passengers per booking. The bookings are subject to applicable fare conditions and seat vacancies in that fare group and seats being available. Seat (s) and fares are not guaranteed until payment is made and a confirmation email is sent.</p>

         <p className="services_content_typography">19. Currently there are following methods of making payment for online reservations made using the Website:- o (i) payment via credit card/debit card online; and o (ii) payment at branch</p>

         <p className="services_content_typography">20. Should you make a purchase through the Website or through any other method, an Electronic ticket will be issued to you. An electronic image of your e-ticket is stored in our reservation system. The itinerary receipt is your confirmation of travel. Please note that in case of any discrepancy appearing between your itinerary receipt and the electronic image of your e-ticket stored on our system, the electronic image of your e-ticket stored on our system shall for all purposes be treated as final and conclusive.</p>

         <p className="services_content_typography">21. You may need to print the itinerary receipt and use it for your access to the airport and check-in. To enter the airport and for check-in, you must present the itinerary receipt along with a valid photo identification Computerized National Identity Card (CNIC) and/or Passport with valid entry permit/visa if traveling internationally.</p>

         <p className="services_content_typography">22. Obtaining visa for international traveling shall be applicant's responsibility. The Company responsibility pertaining to visa (s) shall be limited to completing the visa application form that shall be based upon the information duly submitted/provided by the applicant, drop off and pick up the application to and from the relevant embass</p>

         <p className="services_content_typography">23. You are responsible for the correctness of the details of each passenger's particulars including but not limited to full name, date of birth, gender and age whilst booking online. In no event shall the Company be responsible for incorrectly entered information.</p>

         <p className="services_content_typography">24. You hereby indemnify the Company to the fullest extent from any and against all the liabilities, costs, demands, causes of action, damages and expenses arising out of or in any way related to your breach of any of the provisions of these Terms.</p>

         <p className="services_content_typography">25. You are expressly restricted/prohibited to do the following :- o (i) publishing any Website material in any media; o (ii) selling or otherwise commercializing any Website material; o (iii) using this Website in any way that is, or may be damaging to this Website; o (iv) using this Website contrary to applicable laws and regulations, or in any way , that causes or may cause, harm to the Website, or to any person or business entity; and o (v) engaging in any data mining or data extracting or any other similar activity in relation to this Website or while using this Website.</p>

         <p className="services_content_typography">26. The Company reserves the right to charge listing fees for certain listings, as well as transaction fees based on certain completed transactions using the Website services. You will be liable to pay all the applicable charges, fees, duties, taxes, levies and assessments for availing our Website services.</p>

         <p className="services_content_typography">27. For all those tickets, purchased through Credit Card and/or Debit Card, the Refund request can only be made and processed through the Website. The Refund processing time is thirty (30) business days.</p>

         <p className="services_content_typography">28. This Agreement and its Terms herein shall be governed, construed, interpreted and dealt with in accordance with the laws of Pakistan. The courts at Karachi shall have exclusive jurisdiction to try any matter in relation hereto.</p>


         <p className="services_content_typography">29. NOTICES</p>
         <p className="services_content_typography"> a. Method of Giving Notices </p>
         <p className="services_content_typography">All notices, consent, approval or other communication (each a "Notice") under this Agreement shall be in writing, signed by or on behalf of the person giving it, addressed to the person to whom it is to be given and;
         <li> i. delivered by messenger or Courier; or</li>
         <li> ii. sent by pre-paid mail; or</li>
         <li> iii. transmitted by facsimile, to that person's address.</li></p>

         <p className="services_content_typography">b. Time of Receipt</p>

         <p className="services_content_typography">A Notice given to a person in accordance with this clause is treated as having been given and received:
         <li>i. if delivered, on the day of delivery if delivered before 5:00 PM on a Business Day, otherwise on the next Business Day</li>
         <li> ii. if sent by mail on the 3rd day after the letter containing the Notice, duly stamped, is posted.</li>
         <li> iii. if transmitted by facsimile and the transmission report states that it was sent in full and without error, on the day of transmission if that report states that the transmission was completed before 3:00 PM on a Business Day, otherwise on</li>
         <li> iv. the next Business Day</li>
         </p>

         <p className="services_content_typography">c. Company Address for Notices</p>

         <p className="services_content_typography">All notices, consent, approval or other communication under this Agreement shall be addressed to:
         <li><b>Travel Channel International (Pvt.) Ltd.</b></li>
         <li>3-UGF, Century Tower, Kalma Chowk</li>
         <li>Main Boulevard, Gulberg-III</li>
         <li>Lahore, Pakistan</li>
         <li>Ph: (03 111 147 111)</li>
         </p>

         <p className="services_content_typography">30. Failure of the Company to exercise any right/remedy or at any time to require performance of any provision from you shall in no way constitute a waiver of those rights/remedies or requirement for you to perform such provision.
        </p>

         <p className="services_content_typography">31. If any provision of this Agreement is determined to be invalid or unenforceable in whole or in part, such invalidity or unenforceability shall attach only to such provision or part of such provision and the remaining part of such provision and all other provisions of this Agreement shall continue to be in full force and effect.</p>


      </div>
    </div>
  )
}

export default TermsofServices;