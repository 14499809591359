const RefundPolicyData = [
    {
        id: 1,
        heading: 'Refund Procedure',
        data: `In case you are not able to utilize your ticket or any other travel product, you are required to claim your refund with us within the validity of ticket or product. Refund claims must be made by emailing or calling our Customer Service center. Please note that refunds will always be in accordance to the supplier (airline/hotel/tour operator) rules and policies. Some tickets or products may be non-refundable; in that case no refund will be applicable. Customers are required to take written acknowledgement through hard-copy or email when applying for refund.`
    },
    {
        id: 2,
        heading: 'Time Required',
        data: `Refunds should be made to customers in 30 business days from date of refund/cancellation request. Some ticket or productsâ€™ refund processing time may take even longer. This is caused due to complex billing procedures of airlines and travel suppliers.`
    },
    {
        id: 3,
        heading: 'Mode of Payments',
        data: `Payments made through credit card will be refunded directly to the card, while payments made through other modes will be paid through cheque. Please note that Faremakers.com fees, if any, will not be refundable. Also note that Faremakers.com reserves the right to deduct service fee on refunds.`
    },
]

export default RefundPolicyData;