const SEOAirlinesData = [
    {
      id: 1,
      flightname: "emirates-airline-flights",
      flightCode:'EK',
      Departure:"Lahore (LHE)",
      Arrival:"Dubai (DXB)",
      mainHeading:'Top Destinations of Emirates',
      heading1:'Emirates Top Picks',
      heading2:'Emirates Top Countries',
      heading3:'Emirates Top Cities',
      topPicks: [
        { destination: "Flights to Dubai (DXB)",
         Departure:"Karachi (KHI)",
         Arrival:"Dubai (DXB)"
       },
        { destination: "Flights to Singapore (SIN)" ,
          Departure:"Karachi (KHI)",
          Arrival:"Singapore (SIN)"
       },
        { destination: 'Flights to Port Louis (MRU)',
          Departure:"Karachi (KHI)",
          Arrival:"Port Louis  (MRU)"
       },
        { destination: 'Flights to Hong Kong (HKG)',
          Departure:"Karachi (KHI)",
          Arrival:"Hong Kong (HKG)"
       },
        { destination: 'Flights to Dublin (DUB)',
          Departure:"Dubai (DXB)",
          Arrival:" Dublin (DUB)" 
      }
      ],
      topCountries :[
        {destination:"Flights to Abu Dhabi (AUH)",
        Departure:"Jeddah (JED)",
        Arrival:" Abu Dhabi (AUH)" 
      },
        {destination:"Flights to Auckland (AKL)",
        Departure:"Karachi (KHI)",
        Arrival:"Auckland (AKL)" },
        {destination:"Flights to Melbourne (MEL)",
        Departure:"Islamabad (ISB)",
        Arrival:" Melbourne (MEL)" 
      },
        {destination:"Flights to New Delhi (DEL)",
        Departure:"Islamabad (ISB)",
        Arrival:" New Delhi (DEL)" },
        {destination:"Flights to Toronto (YYZ)",
        Departure:"Karachi (KHI)",
        Arrival:" Toronto (YYZ)" },
      ],
      topCities :[
        {destination:"Flights to Sialkot (SKT)",
        Departure:"Karachi (KHI)",
        Arrival:" Sialkot (SKT)"
        },
        {destination:"Flights to Perth (PER)",
          Departure:"Karachi (KHI)",
          Arrival:"Perth (PER)"},
        {destination:"Flights to Boston (BOS)",
          Departure:"Karachi (KHI)",
          Arrival:"Boston (BOS)"},
        {destination:"Flights to Brisbane (BNE)",
          Departure:"Karachi (KHI)",
          Arrival:"Brisbane (BNE)"
      },
        {destination:"Flights to Budapest (BUD)",
        Departure:"Karachi (KHI)",
        Arrival:" Budapest (BUD)"},
      ]
    },
    {
        id: 2,
        flightname: "qatar-airways-flights",
        flightCode:'QR',
        Departure:"Lahore (LHE)",
        Arrival:"London (LHR)",
        mainHeading:'Qatar Airways Flights',
        heading1:'Qatar Airways Flights To EUROPE',
        heading2:'Qatar Airways Flights to AMERICAS',
        heading3:'Qatar Airways Flights to ASIA',
        topPicks: [
          { destination: "Flights to London (LHR)",
            Departure:"Lahore (LHE)",
            Arrival:"London (LHR)"},
          { destination: "Flights to Paris (PAR)",
            Departure:"Lahore (LHE)",
            Arrival:"Paris (PAR)"},
          { destination: 'Flights to Barcelona (BCN)',
            Departure:"Lahore (LHE)",
            Arrival:"Barcelona (BCN)" },
          { destination: 'Flights to Istanbul (IST)' ,
            Departure:"Lahore (LHE)",
            Arrival:"Istanbul (IST)"},
          { destination: 'Flights to Frankfurt (FRA)',
            Departure:"Lahore (LHE)",
            Arrival:"Frankfurt (FRA)"}
        ],
        topCountries :[
          { destination: "Flights to New York (NYC)",
            Departure:"Lahore (LHE)",
            Arrival:"New York (NYC)"
        },
          { destination: "Flights to Boston (BOS)",
            Departure:"Lahore (LHE)",
            Arrival:"Boston (BOS)" },
          { destination: "Flights to Miami (MIA)",
            Departure:"Lahore (LHE)",
            Arrival:"Miami (MIA)" },
          { destination: "Flights to Washington (WAS)",
            Departure:"Lahore (LHE)",
            Arrival:"Washington (WAS)" },
          { destination: "Flights to Chicago (ORD)",
            Departure:"Lahore (LHE)",
            Arrival:"Chicago (ORD)" },
  
        ],
        topCities :[
          {
            destination: "Flights to Bangkok (BKK)",
            Departure: "Lahore (LHE)",
            Arrival: "Bangkok (BKK)"
          },
          {
            destination: "Flights to Karachi (KHI)",
            Departure: "Lahore (LHE)",
            Arrival: "Karachi (KHI)"
          },
          {
            destination: "Flights to Kuala Lumpur (KUL)",
            Departure: "Lahore (LHE)",
            Arrival: "Kuala Lumpur (KUL)"
          },
          {
            destination: "Flights to Sydney (SYD)",
            Departure: "Lahore (LHE)",
            Arrival: "Sydney (SYD)"
          },
          {
            destination: "Flights to Hong Kong (HKG)",
            Departure: "Lahore (LHE)",
            Arrival: "Hong Kong (HKG)"
          },
        ]
      },
      {
        id: 3,
        flightname: "turkish-airlines-flights",
        flightCode:'TK',
        Departure:"Lahore (LHE)",
        Arrival:"London (LHR)",
        mainHeading:'Top Destinations of Turkish Airlines',
        heading1:'Turkish Airlines Flight to UK',
        heading2:'Turkish Airlines Flights to USA',
        heading3:'Turkish Airlines flights to KSA',
        topPicks: [
          {
            destination: "Flights to London (LHR)",
            Departure: "Lahore (LHE)",
            Arrival: "London (LHR)"
          },
          {
            destination: "Flights to Manchester (MAN)",
            Departure: "Lahore (LHE)",
            Arrival: "Manchester (MAN)"
          },
          {
            destination: "Flights to Edinburgh (EDI)",
            Departure: "Islamabad (ISB)",
            Arrival: "Edinburgh (EDI)"
          },
          {
            destination: "Flights to Birmingham (BHX)",
            Departure: "Lahore (LHE)",
            Arrival: "Birmingham (BHX)"
          }
        ],
        topCountries :[
          {
            destination: "Flights to New York (JFK)",
            Departure: "Lahore (LHE)",
            Arrival: "New York (JFK)"
          },
          {
            destination: "Flights to Washington DC (IAD)",
            Departure: "Lahore (LHE)",
            Arrival: "Washington DC (IAD)"
          },
          {
            destination: "Flights to Miami (MIA)",
            Departure: "Lahore (LHE)",
            Arrival: "Miami (MIA)"
          },
          {
            destination: "Flights to Boston (BOS)",
            Departure: "Karachi (KHI)",
            Arrival: "Boston (BOS)"
          }
  
        ],
        topCities :[
          {
            destination: "Flights to Madina (MED)",
            Departure: "Lahore (LHE)",
            Arrival: "Madina (MED)"
          },
          {
            destination: "Flights to Riyadh (RUH)",
            Departure: "Karachi (KHI)",
            Arrival: "Riyadh (RUH)"
          },
          {
            destination: "Flights to Jeddah (JED)",
            Departure: "Lahore (LHE)",
            Arrival: "Jeddah (JED)"
          },
          {
            destination: "Flights to Dammam (DMM)",
            Departure: "Lahore (LHE)",
            Arrival: "Dammam (DMM)"
          }
        ]
      },
      {
        id: 4,
        flightname: "pakistan-international-airlines-flights",
        flightCode:'PK',
        Departure:"Lahore (LHE)",
        Arrival:"Dubai (DXB)",
        mainHeading:'Top Destinations of PIA',
        heading1:'PIA Domestic Flights',
        heading2:'PIA Flights to Saudi Arabia',
        heading3:'PIA Flights to UAE',
        // subheading1:"PIA Flights to UK",
        // subheading2:'PIA Flights to Top Cities',
        // subheading3:"PIA Flights Top Picks",
        topPicks: [
          {
            destination: "Flights to Lahore (LHE)",
            Departure: "Karachi (KHI)",
            Arrival: "Lahore (LHE)"
          },
          {
            destination: "Flights to Karachi (KHI)",
            Departure: "Islamabad (ISB)",
            Arrival: "Karachi (KHI)"
          },
          {
            destination: "Flights to Islamabad (ISB)",
            Departure: "Peshawar (PEW)",
            Arrival: "Islamabad (ISB)"
          },
          {
            destination: "Flights to Peshawar (PEW)",
            Departure: "Dubai (DXB)",
            Arrival: "Peshawar (PEW)"
          },
          {
            destination: "Flights to Multan (MUX)",
            Departure: "Karachi (KHI)",
            Arrival: "Multan (MUX)"
          }

        ],
        topCountries :[
          {
            destination: "Flights to Jeddah (JED)",
            Departure: "Lahore (LHE)",
            Arrival: "Jeddah (JED)"
          },
          {
            destination: "Flights to Riyadh (RUH)",
            Departure: "Islamabad (ISB)",
            Arrival: "Riyadh (RUH)"
          },
          {
            destination: "Flights to Madina (MED)",
            Departure: "Islamabad (ISB)",
            Arrival: "Madina (MED)"
          },
          {
            destination: "Flights to Dammam (DMM)",
            Departure: "Karachi (KHI)",
            Arrival: "Dammam (DMM)"
          },
          {
            destination: "Flights to Dhahran (DHA)",
            Departure: "Karachi (KHI)",
            Arrival: "Dhahran (DHA)"
          }
          
  
        ],
        topCities :[
          {
            destination: "Flights to Dubai (DXB)",
            Departure: "Karachi (KHI)",
            Arrival: "Dubai (DXB)"
          },
          {
            destination: "Flights to Abu Dhabi (AUH)",
            Departure: "Islamabad (ISB)",
            Arrival: "Abu Dhabi (AUH)"
          },
          {
            destination: "Flights to Sharjah (SHJ)",
            Departure: "Peshawar (PEW)",
            Arrival: "Sharjah (SHJ)"
          },
          {
            destination: "Flights to Al Ain (AAN)",
            Departure: "Lahore (LHE)",
            Arrival: "Al Ain (AAN)"
          },
          {
            destination: "Flights to Ras Al Khaimah (RKT)",
            Departure: "Karachi (KHI)",
            Arrival: "Ras Al Khaimah (RKT)"
          }

        ],
        // subheading1data :[
        //     {destination:"Flights to London(LON)"},
        //     {destination:"Flights to Manchester(MAN)"},
        //     {destination:"Flights to Brimingham(BHX)"},
        //   ],
        //   subheading2data :[
        //     {destination:"Flights to Toronto(YTO)"},
        //     {destination:"Flights to Beijing(BJS)"},
        //     {destination:"Flights to Paris(PAR)"},
        //   ],
        //   subheading3data :[
        //     {destination:"Flights to Najaf(NJF)"},
        //     {destination:"Flights to Muscat(MCT)"},
        //     {destination:"Flights to Doha(DOH)"},

        //   ],
      },
      {
        id:5,
        flightname: "oman-air-flights",
        flightCode:'WY',
        Departure:"Lahore (LHE)",
        Arrival:"Muscat (MCT)",
        mainHeading:'Top Destinations of Oman Air',
        heading1:'Oman Air Flights To GULF',
        heading2:'Oman Air Flights to SOUTH ASIA',
        heading3:'Oman Air Flights to EUROPE',
        topPicks: [
          {
            destination: "Flights to Muscat (MCT)",
            Departure: "Lahore (LHE)",
            Arrival: "Muscat (MCT)"
          },
          {
            destination: "Flights to Kuwait City (KWI)",
            Departure: "Lahore (LHE)",
            Arrival: "Kuwait City (KWI)"
          },
          {
            destination: "Flights to Doha (DOH)",
            Departure: "Karachi (KHI)",
            Arrival: "Doha (DOH)"
          },
          {
            destination: "Flights to Riyadh (RUH)",
            Departure: "Lahore (LHE)",
            Arrival: "Riyadh (RUH)"
          },
          {
            destination: "Flights to Dubai (DXB)",
            Departure: "Lahore (LHE)",
            Arrival: "Dubai (DXB)"
          }
        ],
        topCountries :[
          {
            destination: "Flights to Dhaka (DAC)",
            Departure: "Karachi (KHI)",
            Arrival: "Dhaka (DAC)"
          },
          {
            destination: "Flights to Mumbai (BOM)",
            Departure: "Karachi (KHI)",
            Arrival: "Mumbai (BOM)"
          },
          {
            destination: "Flights to Islamabad (ISB)",
            Departure: "Karachi (KHI)",
            Arrival: "Islamabad (ISB)"
          },
          {
            destination: "Flights to Kathmandu (KTM)",
            Departure: "Karachi (KHI)",
            Arrival: "Kathmandu (KTM)"
          },
          {
            destination: "Flights to Colombo (CMB)",
            Departure: "Lahore (LHE)",
            Arrival: "Colombo (CMB)"
          }
  
        ],
        topCities :[
          {
            destination: "Flights to Paris (CDG)",
            Departure: "Lahore (LHE)",
            Arrival: "Paris (CDG)"
          },
          {
            destination: "Flights to Berlin (TXL)",
            Departure: "Lahore (LHE)",
            Arrival: "Berlin (TXL)"
          },
          {
            destination: "Flights to London (LHR)",
            Departure: "Lahore (LHE)",
            Arrival: "London (LHR)"
          },
          {
            destination: "Flights to Rome (FCO)",
            Departure: "Lahore (LHE)",
            Arrival: "Rome (FCO)"
          },
          {
            destination: "Flights to Zurich (ZRH)",
            Departure: "Lahore (LHE)",
            Arrival: "Zurich (ZRH)"
          }
        ]
      },
      {
        id: 6,
        flightname: "fly-dubai-flights",
        flightCode:'FZ',
        Departure:"Karachi (KHI)",
        Arrival:"Dubai (DXB)",
        mainHeading:'Top Destinations of FlyDubai',
        heading1:'FlyDubai Top Picks',
        heading2:'FlyDubai Top Countries',
        heading3:'FlyDubai Top Cities',
        topPicks: [
          {
            destination: "Flights to Dubai(DXB)",
            Departure: "Karachi (KHI)",
            Arrival: "Dubai (DXB)"
          },
          {
            destination: "Flights to Singapore (SIN)",
            Departure: "Lahore (LHE)",
            Arrival: "Singapore (SIN)"
          },
          {
            destination: "Flights to Mauritius (MRU)",
            Departure: "Islamabad (ISB)",
            Arrival: "Mauritius (MRU)"
          },
          {
            destination: "Flights to Hong Kong (HKG)",
            Departure: "Karachi (KHI)",
            Arrival: "Hong Kong (HKG)"
          },
          {
            destination: "Flights to Dublin (DUB)",
            Departure: "Lahore (LHE)",
            Arrival: "Dublin (DUB)"
          }
        ],
        topCountries :[
          {
            destination: "Flights to United Arab Emirates (DXB)",
            Departure: "Karachi (KHI)",
            Arrival: "Dubai (DXB)"
          },
          {
            destination: "Flights to New Zealand (AKL)",
            Departure: "Karachi (KHI)",
            Arrival: "Auckland (AKL)"
          },
          {
            destination: "Flights to Australia (SYD)",
            Departure: "Islamabad (ISB)",
            Arrival: "Sydney (SYD)"
          },
          {
            destination: "Flights to India (DEL)",
            Departure: "Karachi (KHI)",
            Arrival: "New Delhi (DEL)"
          },
          {
            destination: "Flights to Canada (YYZ)",
            Departure: "Lahore (LHE)",
            Arrival: "Toronto (YYZ)"
          }
  
        ],
        topCities :[
          {
            destination: "Flights to Sialkot (SKT)",
            Departure: "Karachi (KHI)",
            Arrival: "Sialkot (SKT)"
          },
          {
            destination: "Flights to Perth (PER)",
            Departure: "Islamabad (ISB)",
            Arrival: "Perth (PER)"
          },
          {
            destination: "Flights to Boston (BOS)",
            Departure: "Karachi (KHI)",
            Arrival: "Boston (BOS)"
          },
          {
            destination: "Flights to Brisbane (BNE)",
            Departure: "Lahore (LHE)",
            Arrival: "Brisbane (BNE)"
          },
          {
            destination: "Flights to Budapest (BUD)",
            Departure: "Islamabad (ISB)",
            Arrival: "Budapest (BUD)"
          }
        ]
      },
      {
        id: 7,
        flightname: "etihad-airways-flights",
        flightCode:'EY',
        Departure:"Lahore (LHE)",
        Arrival:"London (LHR)",
        mainHeading:'Top Destinations of Etihad Airways',
        heading1:'Etihad Airways Top Picks',
        heading2:'Etihad Airways Top Countries',
        heading3:'Etihad Airways Top Cities',
        topPicks: [
          {
            destination: 'Flights to London (LON)',
            Departure: 'Lahore (LHE)',
            Arrival: 'London (LON)'
          },
          {
            destination: 'Flights to Toronto (YTO)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Toronto (YTO)'
          },
          {
            destination: 'Flights to Beijing (BJS)',
            Departure: 'Dubai (DXB)',
            Arrival: 'Beijing (BJS)'
          },
          {
            destination: 'Flights to New York (NYC)',
            Departure: 'Lahore (LHE)',
            Arrival: 'New York (NYC)'
          },
          {
            destination: 'Flights to Istanbul (IST)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Istanbul (IST)'
          }
          ],
      topCountries: [
        {
          destination: 'Flights to UAE (DXB)',
          Departure: 'Lahore (LHE)',
          Arrival: 'Dubai (DXB)'
        },
        {
          destination: 'Flights to UK (LON)',
          Departure: 'Lahore (LHE)',
          Arrival: 'London (LON)'
        },
        {
          destination: 'Flights to Saudi Arabia (JED)',
          Departure: 'Lahore (LHE)',
          Arrival: 'Jeddah (JED)'
        },
        {
          destination: 'Flights to India (DEL)',
          Departure: 'Lahore (LHE)',
          Arrival: 'New Delhi (DEL)'
        },
        {
          destination: 'Flights to Australia (SYD)',
          Departure: 'Lahore (LHE)',
          Arrival: 'Sydney (SYD)'
        }
          ],
       topCities: [
        {
          destination: 'Flights to Lahore (LHE)',
          Departure: 'Karachi (KHI)',
          Arrival: 'Lahore (LHE)'
        },
        {
          destination: 'Flights to Manila (MNL)',
          Departure: 'Lahore (LHE)',
          Arrival: 'Manila (MNL)'
        },
        {
          destination: 'Flights to Medina (MED)',
          Departure: 'Karachi (KHI)',
          Arrival: 'Medina (MED)'
        },
        {
          destination: 'Flights to Singapore (SIN)',
          Departure: 'Lahore (LHE)',
          Arrival: 'Singapore (SIN)'
        },
        {
          destination: 'Flights to Manchester (MAN)',
          Departure: 'Lahore (LHE)',
          Arrival: 'Manchester (MAN)'
        }
          ],
      },
      
      {
        id: 8,
        flightname: "air-sial-flights",
        flightCode:'PF',
        Departure:"Lahore (LHE)",
        Arrival:"Jeddah (JED)",
        mainHeading:'Air Sial Top Destinations',
        heading1:'Air Sial Top Picks',
        heading2:'Air Sial Top Countries',
        heading3:'Air Sial Top Cities',
        topPicks: [
          {
            destination: 'Flights to Lahore',
            Departure: 'Jeddah (JED)',
            Arrival: 'Lahore (LHE)'
          },
          {
            destination: 'Flights to Karachi',
            Departure: 'Islamabad (ISB)',
            Arrival: 'Karachi (KHI)'
          }
        ],
        topCountries: [
          {
            destination: 'Flights to Islamabad',
            Departure: 'Karachi (KHI)',
            Arrival: 'Islamabad (ISB)'
          },
          {
            destination: 'Flights to Sialkot',
            Departure: 'Karachi (KHI)',
            Arrival: 'Sialkot (SKT)'
          }
        ],
        topCities: [
          {
            destination: 'Flights to Peshawar',
            Departure: 'Karachi (KHI)',
            Arrival: 'Peshawar (PEW)'
          },
          {
            destination: 'Flights to Sabiha',
            Departure: 'Karachi (KHI)',
            Arrival: 'Sabiha (SAW)'
          }
        ],
      },
      {
        id: 9,
        flightname: "virgin-atlantic-flights",
        flightCode:'VS',
        Departure:"Boston (BOS)",
        Arrival:"London (LHR)",
        mainHeading:'Virgin Atlantic Airways Top Destinations',
        heading1:'Virgin Atlantic Top Picks',
        heading2:'Virgin Atlantic Top Countries',
        heading3:'Virgin Atlantic Top Cities',
        topPicks: [
          {
            destination: 'Flights to London (LHR)',
            Departure: 'Islamabad (ISB)',
            Arrival: 'London (LHR)'
          },
          {
            destination: 'Flights to Manchester (MAN)',
            Departure: 'Islamabad (ISB)',
            Arrival: 'Manchester (MAN)'
          },
          {
            destination: 'Flights to Los Angeles (LAX)',
            Departure: 'Islamabad (ISB)',
            Arrival: 'Los Angeles (LAX)'
          }
        ],
        topCountries: [
          {
            destination: 'Flights to Birmingham (BHX)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Birmingham (BHX)'
          },
          {
            destination: 'Flights to New York (JFK)',
            Departure: 'Lahore (LHE)',
            Arrival: 'New York (JFK)'
          },
          {
            destination: 'Flights to Shanghai (PVG)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Shanghai (PVG)'
          }
        ],
        topCities: [
          {
            destination: 'Flights to Miami (MIA)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Miami (MIA)'
          },
          {
            destination: 'Flights to Boston (BOS)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Boston (BOS)'
          },
          {
            destination: 'Flights to Dubai (DXB)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Dubai (DXB)'
          }
        ],
      },
      {
        id: 10,
        flightname: "thai-airways-flights",
        flightCode: 'TG',
        Departure:"Lahore (LHE)",
        Arrival:"London (LHR)",
        mainHeading: 'Top Destinations of Thai Airline',
        heading1: 'Thai Airline Top Picks',
        heading2: 'Thai Airline Top Countries',
        heading3: 'Thai Airline Top Cities',
        topPicks: [
          {
            destination: 'Flights to Bangkok (BKK)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Bangkok (BKK)'
          },
          {
            destination: 'Flights to London (LON)',
            Departure: 'Lahore (LHE)',
            Arrival: 'London (LON)'
          },
          {
            destination: 'Flights to Muscat (MCT)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Muscat (MCT)'
          },
          {
            destination: 'Flights to Dubai (DXB)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Dubai (DXB)'
          },
          {
            destination: 'Flights to Beijing (BJS)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Beijing (BJS)'
          }
        ],
        topCountries: [
          {
            destination: 'Flights to Sydney (SYD)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Sydney (SYD)'
          },
          {
            destination: 'Flights to Beijing (BJS)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Beijing (BJS)'
          },
          {
            destination: 'Flights to Karachi (KHI)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Karachi (KHI)'
          },
          {
            destination: 'Flights to New Delhi (DEL)',
            Departure: 'Lahore (LHE)',
            Arrival: 'New Delhi (DEL)'
          },
          {
            destination: 'Flights to Frankfurt (FRA)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Frankfurt (FRA)'
          }
        ],
        topCities: [
          {
            destination: 'Flights to Paris (PAR)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Paris (PAR)'
          },
          {
            destination: 'Flights to Oslo (OSL)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Oslo (OSL)'
          },
          {
            destination: 'Flights to Singapore (SIN)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Singapore (SIN)'
          },
          {
            destination: 'Flights to Kuala Lumpur (KUL)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Kuala Lumpur (KUL)'
          },
          {
            destination: 'Flights to Jakarta (JKT)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Jakarta (JKT)'
          }
        ],
      },
      {
        id: 11,
        flightname: "kuwait-airways-flights",
        flightCode: 'KU',
        Departure:"Lahore (LHE)",
        Arrival:"Kuwait City (KWI)",
        mainHeading: 'Top Destinations of Kuwait Airways',
        heading1: 'Kuwait Airways Top Picks',
        heading2: 'Kuwait Airways Top Countries',
        heading3: 'Kuwait Airways Top Cities',
        topPicks: [
          {
            destination: 'Flights to London (LON)',
            Departure: 'Lahore (LHE)',
            Arrival: 'London (LON)'
          },
          {
            destination: 'Flights to New York (NYC)',
            Departure: 'Lahore (LHE)',
            Arrival: 'New York (NYC)'
          },
          {
            destination: 'Flights to Milan (MIL)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Milan (MIL)'
          }
          

        ],
        topCountries: [
          {
            destination: 'Flights to Frankfurt (FRA)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Frankfurt (FRA)'
          },
          {
            destination: 'Flights to Istanbul (IST)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Istanbul (IST)'
          },
          {
            destination: 'Flights to Rome (FCO)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Rome (FCO)'
          }
        ],
        topCities: [
          {
            destination: 'Flights to Baku (GYD)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Baku (GYD)'
          },
          {
            destination: 'Flights to Jeddah (JED)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Jeddah (JED)'
          },
          {
            destination: 'Flights to Bangkok (BKK)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Bangkok (BKK)'
          }
        ],
      },
      {
        id: 12,
        flightname: "china-southern-airlines-flights",
        flightCode: 'CZ',
        Departure:"Guangzhou (CAN)",
        Arrival:"Sydney (SYD)",
        mainHeading: 'China Southern Airlines Top Destinations',
        heading1: 'China Southern Top Picks',
        heading2: 'China Southern Top Countries',
        heading3: 'China Southern Top Cities',
        topPicks: [
          {
            destination: 'Flights to GuangZhou (CAN)',
            Departure: 'Islamabad (ISB)',
            Arrival: 'GuangZhou (CAN)'
          },
          {
            destination: 'Flights to BeiJing (PEK)',
            Departure: ' Dubai (DXB)',
            Arrival: 'BeiJing (PEK)'
          },
          {
            destination: 'Flights to HangZhou (HGH)',
            Departure: 'Dubai (DXB)',
            Arrival: 'HangZhou (HGH)'
          }
          

        ],
        topCountries: [
          {
            destination: 'Flights to Urumqi (URC)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Urumqi (URC)'
          },
          {
            destination: 'Flights to Shanghai (PVG)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Shanghai (PVG)'
          },
          {
            destination: 'Flights to Xian Xianyang (XIY)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Xian Xianyang (XIY)'
          }

        ],
        topCities: [
          {
            destination: 'Flights to Los Angeles (LAX)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Los Angeles (LAX)'
          },
          {
            destination: 'Flights to London (LHR)',
            Departure: 'Lahore (LHE)',
            Arrival: 'London (LHR)'
          },
          {
            destination: 'Flights to Singapore (SIN)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Singapore (SIN)'
          }

        ],
      },
      {
        id: 13,
        flightname: "saudi-arabian-airline-flights",
        flightCode: 'SV',
        Departure:" Jeddah (JED)",
        Arrival:"Riyadh (RUH)",
        mainHeading: 'Saudi Arabian Airlines Top Destinations',
        heading1: 'Saudi Arabian  Top Picks',
        heading2: 'Saudi Arabian  Top Countries',
        heading3: 'Saudi Arabian  Top Cities',
        topPicks: [
          {
            destination: 'Flights to Jeddah (JED)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Jeddah (JED)'
          },
          {
            destination: 'Flights to Medina (MED)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Medina (MED)'
          },
          {
            destination: 'Flights to Riyadh (RUH)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Riyadh (RUH)'
          }

        ],
        topCountries: [
          {
            destination: 'Flights to Dammam (DMM)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Dammam (DMM)'
          },
          {
            destination: 'Flights to Madinah (MED)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Madinah (MED)'
          },
          {
            destination: 'Flights to Abha (AHB)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Abha (AHB)'
          }
        ],
        topCities: [
          {
            destination: 'Flights to London (LON)',
            Departure: 'Lahore (LHE)',
            Arrival: 'London (LON)'
          },
          {
            destination: 'Flights to Frankfurt (FRA)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Frankfurt (FRA)'
          },
          {
            destination: 'Flights to Johannesburg (JNB)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Johannesburg (JNB)'
          }

        ],
      },
      {
        id: 14,
        flightname: "air-arabia-flights",
        flightCode:'SV',
        Departure:"Lahore (LHE)",
        Arrival:"Dubai (DXB)",
        mainHeading: 'Air Arabia Airlines Top Destinations',
        heading1: 'Air Arabia  Top Picks',
        heading2: 'Air Arabia  Top Countries',
        heading3: 'Air Arabia  Top Cities',
        topPicks: [
          {
            destination: 'Flights to Dubai (DXB)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Dubai (DXB)'
          },
          {
            destination: 'Flights to Abu Dhabi (AUH)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Abu Dhabi (AUH)'
          }
        ],
        topCountries: [
          {
            destination: 'Flights to Ras Al Khaimah (RKT)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Ras Al Khaimah (RKT)'
          },
          {
            destination: 'Flights to Al Ain (AAN)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Al Ain (AAN)'
          }
        ],
        topCities: [
          {
            destination: 'Flights to Jizan (GIZ)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Jizan (GIZ)'
          },
          {
            destination: 'Flights to Sabiha (SAW)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Sabiha (SAW)'
          }
        ],
      },
      {
        id: 15,
        flightname: "air-malindo-flights",
        flightCode: 'OD',
        Departure:"Lahore (LHE)",
        Arrival:"Kuala Lumpur (KUL)",
        mainHeading: 'Air Malindo Airlines Top Destinations',
        topPicks: [
          {
            destination: 'Flights to Kuala Lumpur (KUL)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Kuala Lumpur (KUL)'
          },
          {
            destination: 'Flights to Bangkok (BKK)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Bangkok (BKK)'
          }
        ],
        topCountries: [
          {
            destination: 'Flights to Melbourne (MEL)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Melbourne (MEL)'
          },
          {
            destination: 'Flights to Colombo (CMB)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Colombo (CMB)'
          }
        ],
        topCities: [
          {
            destination: 'Flights to Jakarta (JKT)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Jakarta (JKT)'
          },
          {
            destination: 'Flights to Singapore (SIN)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Singapore (SIN)'
          }
        ],
      },
      {
        id: 16,
        flightname: "british-airways-flights",
        flightCode: 'BA',
        Departure:"Lahore (LHE)",
        Arrival:"London (LHR)",
        mainHeading: 'British Airways Top Destinations',
        topPicks: [
          {
            destination: 'Flights to London (LHR)',
            Departure: 'Lahore (LHE)',
            Arrival: 'London (LHR)'
          },
          {
            destination: 'Flights to New York (JFK)',
            Departure: 'Lahore (LHE)',
            Arrival: 'New York (JFK)'
          },
          {
            destination: 'Flights to Toronto (YYZ)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Toronto (YYZ)'
          }

        ],
        topCountries: [
          {
            destination: 'Flights to Washington (IAD)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Washington (IAD)'
          },
          {
            destination: 'Flights to Chicago (ORD)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Chicago (ORD)'
          },
          {
            destination: 'Flights to Boston (BOS)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Boston (BOS)'
          }
        ],
        topCities: [
          {
            destination: 'Flights to Los Angeles (LAX)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Los Angeles (LAX)'
          },
          {
            destination: 'Flights to Frankfurt (FRA)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Frankfurt (FRA)'
          },
          {
            destination: 'Flights to Rome (FCO)',
            Departure: 'Lahore (LHE)',
            Arrival: 'Rome (FCO)'
          }

        ],
      },
     
    
  ];

  export default  SEOAirlinesData;