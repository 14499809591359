// -------------------------Brand icon----------------
import faremakerlogo from '../assets/faremaker-logo.png';
import bankicon from '../assets/icon/icons8-bank-19.png';
import whatsappicon from '../assets/icon/whatsapp-logo.png';
import cautionicon from '../assets/icon/caution.png';

// ------------------------Payment-------------------------
import hbllogo from '../assets/paymentlogo/paymentlogo (1).png';
import payprologo from '../assets/paymentlogo/paymentlogo (2).png';
import digicertlogo from '../assets/paymentlogo/paymentlogo (6).png';
import iatalogo from '../assets/paymentlogo/paymentlogo (5).png';
import mastercardlogo from '../assets/paymentlogo/paymentlogo (4).png';
import visacardlogo from '../assets/paymentlogo/paymentlogo (3).png';
import jazzcash_Logo from '../assets/paymentlogo/jazzcashlogo.png'
import easypaisa_logo from '../assets/paymentlogo/easypaisa.png';

import swichLogo from '../assets/paymentlogo/swich-logo.png';

// -----------------------Flight, Hotel icon--------------------
import hotellogo from '../assets/hotel-mobile.webp';
import planelogo from '../assets/plane-mobile.png';
import planeImage from '../assets/plane.png';
import hotelImage from '../assets/hotel-sign.webp';
import sunUmbrellaImage from '../assets/sun-umbrella.png';
import qaterLogo from '../assets/icon/QR.gif';
import MallLogo from '../assets/MALL-icon-150x150_wi8.png';
import MallLogoBlue from '../assets/mall-icon-mob.png';
import MallIconMob from '../assets/mall-icon-mob.png';
import liveIcon from '../assets/live-icon.png';
import liveIconMob from '../assets/Live-streaming-icon-blue.png';

// ----------------------City images------------------------------
import dubaiPics from '../assets/city/Dubai.png';
// import lahorePics from '../assets/city/lahore-lahore-city-lhr-lahore-pakistan.jpg'
import lahorePics from '../assets/city/lahore-lahore-city-lhr-lahore-pakistan.png';
import karachiPics from '../assets/city/quaid-azam-bright.png';
import islamabadPics from '../assets/city/shah-faisal-mosque.png';
import londonPics from '../assets/city/tower-bridge-london-england_980x650.png';

// ----------------------flight booking images------------------------------
import pakistanMap from '../assets/pakistan-map.png';
import mobileScaner from  '../assets/mobile-scaner.png';
import officeLocation from "../assets/pkr_map2.png";

// ----------------------flight booking Images------------------------------
import bikepic from '../assets/RIDER-Blue-150x150.png';
import bankpic from '../assets/online-banking-150x150.png';


// ----------------------Slider otp Images------------------------------
import otpSlider2 from '../assets/slider2.png';
import otpSlider3 from '../assets/slider3.png';

// ----------------------Mobile Images------------------------------
import caution from '../assets/icon/caution.png';
import mobBanking from '../assets/icon/icons8-bank-19.png';

// ---------------------QR Banners Images------------------------------
import QRBanner from '../assets/QR-Banner.png';
import AppStore from '../assets/APP-STORE.png';
import Googleplay from '../assets/GOOGLE-PLAY.png';

import AirAsiaFlag from '../assets/AirAsiaFlag.png';
import dataNotfound from '../assets/data_not_found.png';

// -----------------------Itineray Details Images  --------------------
import passport_Fname from '../assets/ItineraryDetails/passport_Fname.png';
import passport_Lname  from '../assets/ItineraryDetails/passport_Lname.png';

//---------------------Extra Baggages ---------------------------
import nill_Bagg from '../assets/extra_baggages/no-baggage.jpg';
import standerd_Bagg from '../assets/extra_baggages/standard-baggage.jpg';
import extra_Bagg from '../assets/extra_baggages/double-baggage.jpg';

import UAN from '../assets/icon/UAN-icon.png';
import Mail from '../assets/icon/email-icon.png';
import FMAbout from '../../src/assets/FMAbout.webp';
import SCBank from '../../src/assets/icon/SCBank.webp';
import MBBank from '../../src/assets/icon/MB-bank.png';


import refundPolicy from '../../src/assets/refundPolicy.jpeg';
import career from '../../src/assets/careers.webp';

import userProfile from '../../src/assets/userProfile.jpg'; 
import announcement from '../../src/assets/announcement.png';

import destinationMap from '../../src/assets/destinationmap.png';
import saiRouteMap from '../../src/assets/sairouteMap.jpg';

// --------------------Blogs------------------------------------

// import blogimg1 from '../../src/assets/Blogs/banner2.png';
// import blogimg2 from '../../src/assets/Blogs/girlb.jpg';
// import blogimg3 from '../../src/assets/Blogs/forest.jpg';
// import blogimg4 from '../../src/assets/Blogs/kashmir.jpg';

import blogBackGround from '../../src/assets/Blogs/intro.png';
import fmBlogLogo from '../../src/assets/fm_blog.png';
import underConstruction from '../../src/assets/worker-with-briefcase-blocked-way.jpg';
import gearImage from '../../src/assets/gears.jpg';
import Robot from '../../src/assets/robot.jpg';
import ArrangeCall from '../../src/assets/Arrange-A-Call.png';

import ArrangeCallimg1 from '../../src/assets/ARRANGE-CALL-1.png';
import ArrangeCallimg2 from '../../src/assets/ARRANGE-CALL-2.png';
import ArrangeCallimg3 from '../../src/assets/ARRANGE-CALL-3.png';

import LahoreTower from '../../src/assets/icon/tower.png';
import OtherCitiesImg from '../../src/assets/icon/faisal-mosque.png';

// ---------------------Footer Flights Logo -----------------------------
import emirateLogo from '../../src/assets/footerFlightsLogo/EMIRATES.png';
import piaLogo from '../../src/assets/footerFlightsLogo/PIA.png';
import qatarLogo from '../../src/assets/footerFlightsLogo/qatar.png';


// --------------City Updated Images --------------------------
import sydney from '../../src/assets/city/updated/sydney.png';
import London from '../../src/assets/city/updated/london.png';
import Dubai from '../../src/assets/city/updated/dubai.png';
import NewYork from '../../src/assets/city/updated/newYork.png';
import Toronto from '../../src/assets/city/updated/toronto.png';

// ----------------City BackGround Banners---------------------------
// import cityBanner from '../../src/assets/city/banners/hero-image_2.jpg';

export const extra_baggages = [
    nill_Bagg,   
    standerd_Bagg,
    extra_Bagg
  ];
export default faremakerlogo ;

export {bankicon,whatsappicon,cautionicon, hbllogo,payprologo,iatalogo,mastercardlogo,digicertlogo,visacardlogo,jazzcash_Logo,hotellogo,planelogo,UAN,Mail,FMAbout,SCBank,MBBank,
    planeImage,hotelImage,sunUmbrellaImage,qaterLogo,dubaiPics,lahorePics,karachiPics,islamabadPics,londonPics,pakistanMap,mobileScaner,officeLocation
    ,bikepic,bankpic,QRBanner,MallLogo,otpSlider2,otpSlider3,caution,mobBanking,MallLogoBlue,MallIconMob,liveIcon,liveIconMob,AppStore,easypaisa_logo,Googleplay,AirAsiaFlag,dataNotfound,passport_Fname,passport_Lname,nill_Bagg,standerd_Bagg,extra_Bagg,refundPolicy,career,userProfile,announcement,destinationMap,saiRouteMap,blogBackGround,fmBlogLogo,underConstruction,gearImage,Robot,swichLogo,ArrangeCall,ArrangeCallimg1,ArrangeCallimg2,ArrangeCallimg3,LahoreTower,OtherCitiesImg,emirateLogo,piaLogo,qatarLogo,sydney,London,Dubai,NewYork,Toronto
};