import React from "react";
import TermsofServices from "../Components/Commom/TermsofServices";

const Termsofservice = () =>
{
    return(
    <div className="container">
        <TermsofServices/>
    </div>
    );
}

export default Termsofservice;