export const DomesticRoutes = [
  { from: 'Karachi', to: 'Islamabad', departCode: 'KHI', ArrivalCode: 'ISB' },
  { from: 'Lahore', to: 'Karachi', departCode: 'LHE', ArrivalCode: 'KHI' },
  // { from: 'Lahore', to: 'Islamabad', departCode: 'LHE', ArrivalCode: 'ISB' },
  { from: 'Lahore', to: 'Peshawar', departCode: 'LHE', ArrivalCode: 'PEW' },
  // { from: 'Lahore', to: 'Faisalabad', departCode: 'LHE', ArrivalCode: 'LYP' },
  { from: 'Lahore', to: 'Quetta', departCode: 'LHE', ArrivalCode: 'UET' },
  // { from: 'Lahore', to: 'Multan', departCode: 'LHE', ArrivalCode: 'MUX' },
  { from: 'Faisalabad', to: 'Karachi', departCode: 'LYP', ArrivalCode: 'KHI' },
  // { from: 'Faisalabad', to: 'Islamabad', departCode: 'LYP', ArrivalCode: 'ISB' },
  // { from: 'Faisalabad', to: 'Peshawar', departCode: 'LYP', ArrivalCode: 'PEW' },
  { from: 'Karachi', to: 'Lahore', departCode: 'KHI', ArrivalCode: 'LHE' },
  { from: 'Karachi', to: 'Peshawar', departCode: 'KHI', ArrivalCode: 'PEW' },
  // { from: 'Karachi', to: 'Faisalabad', departCode: 'KHI', ArrivalCode: 'LYP' },
  { from: 'Karachi', to: 'Quetta', departCode: 'KHI', ArrivalCode: 'UET' },
  { from: 'Karachi', to: 'Multan', departCode: 'KHI', ArrivalCode: 'MUX' },
  // { from: 'Faisalabad', to: 'Lahore', departCode: 'LYP', ArrivalCode: 'LHE' },
  // { from: 'Faisalabad', to: 'Quetta', departCode: 'LYP', ArrivalCode: 'UET' },
  // { from: 'Faisalabad', to: 'Multan', departCode: 'LYP', ArrivalCode: 'MUX' },
  { from: 'Islamabad', to: 'Karachi', departCode: 'ISB', ArrivalCode: 'KHI' },
  // { from: 'Islamabad', to: 'Lahore', departCode: 'ISB', ArrivalCode: 'LHE' },
  // { from: 'Islamabad', to: 'Peshawar', departCode: 'ISB', ArrivalCode: 'PEW' },
  // { from: 'Islamabad', to: 'Faisalabad', departCode: 'ISB', ArrivalCode: 'LYP' },
  { from: 'Islamabad', to: 'Quetta', departCode: 'ISB', ArrivalCode: 'UET' },
  { from: 'Islamabad', to: 'Multan', departCode: 'ISB', ArrivalCode: 'MUX' },
  // { from: 'Quetta', to: 'Karachi', departCode: 'UET', ArrivalCode: 'KHI' },
  { from: 'Quetta', to: 'Islamabad', departCode: 'UET', ArrivalCode: 'ISB' },
  { from: 'Quetta', to: 'Peshawar', departCode: 'UET', ArrivalCode: 'PEW' },
  { from: 'Peshawar', to: 'Karachi', departCode: 'PEW', ArrivalCode: 'KHI' },
  // { from: 'Peshawar', to: 'Islamabad', departCode: 'PEW', ArrivalCode: 'ISB' },
  { from: 'Peshawar', to: 'Lahore', departCode: 'PEW', ArrivalCode: 'LHE' },
  // { from: 'Peshawar', to: 'Faisalabad', departCode: 'PEW', ArrivalCode: 'LYP' },
  { from: 'Peshawar', to: 'Quetta', departCode: 'PEW', ArrivalCode: 'UET' },
  { from: 'Peshawar', to: 'Multan', departCode: 'PEW', ArrivalCode: 'MUX' },
  // { from: 'Quetta', to: 'Faisalabad', departCode: 'UET', ArrivalCode: 'LYP' },
  { from: 'Quetta', to: 'Lahore', departCode: 'UET', ArrivalCode: 'LHE' },
  { from: 'Quetta', to: 'Multan', departCode: 'UET', ArrivalCode: 'MUX' },
  ];
  