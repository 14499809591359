import * as images from '../images';
export const  PopularairlineRoute = [
    { id: 1, name: 'emirates-airline-flights', displayName: 'Emirates Airline' ,imgPath:require('../../assets/footerFlightsLogo/EMIRATES.png')},
    { id: 2, name: 'qatar-airways-flights', displayName: 'Qatar Airways', imgPath:require('../../assets/footerFlightsLogo/qatar.png') },
    { id: 3, name: 'turkish-airlines-flights', displayName: 'Turkish Airlines', imgPath:require('../../assets/footerFlightsLogo/turkish.png') },
    { id: 4, name: 'pakistan-international-airlines-flights', displayName: 'PIA' ,imgPath: require('../../assets/footerFlightsLogo/PIA.png') },
    { id: 5, name: 'oman-air-flights', displayName: 'Oman Air',imgPath: require('../../assets/footerFlightsLogo/oman.png') },
    { id: 6, name: 'fly-dubai-flights', displayName: 'Fly Dubai',imgPath: require('../../assets/footerFlightsLogo/flydubai.png') },
    { id: 7, name: 'etihad-airways-flights', displayName: 'Etihad Airways',imgPath: require('../../assets/footerFlightsLogo/etihad.png') },
    { id: 8, name: 'air-sial-flights', displayName: 'Air Sial' ,imgPath: require('../../assets/footerFlightsLogo/Airsial.png')},
    { id: 9, name: 'virgin-atlantic-flights', displayName: 'Virgin Atlantic' ,imgPath: require('../../assets/footerFlightsLogo/virginatlantic.png')},
    { id: 10, name: 'thai-airways-flights', displayName: 'Thai Airways' ,imgPath: require('../../assets/footerFlightsLogo/thai.png')},
    { id: 11, name: 'kuwait-airways-flights', displayName: 'Kuwait Airways' ,imgPath: require('../../assets/footerFlightsLogo/kuwait.png')},
    { id: 12, name: 'china-southern-airlines-flights', displayName: 'China Southern',imgPath: require('../../assets/footerFlightsLogo/china-southern.png') },
    { id: 13, name: 'saudi-arabian-airline-flights', displayName: 'Saudi Arabian',imgPath: require('../../assets/footerFlightsLogo/al-saudia.png') },
    { id: 14, name: 'air-arabia-flights', displayName: 'Air Arabia',imgPath: require('../../assets/footerFlightsLogo/air-arabia.png') },
    { id: 15, name: 'air-malindo-flights', displayName: 'Air Malindo',imgPath: require('../../assets/footerFlightsLogo/air-malindo.png') },
    { id: 16, name: 'british-airways-flights', displayName: 'British Airways',imgPath: require('../../assets/footerFlightsLogo/british-airways.png') },
  ];