import React ,{useState ,useEffect} from 'react';
import { useLocation } from 'react-router';

const ScrollToTop = () =>{
const {pathname} = useLocation();
console.log("------------>pathname",pathname);

useEffect(()=>{
    window.scrollTo(0,0);
},[pathname]);
};

export default ScrollToTop;