const TermsConditionsData = [
    {
        id: 1,
        heading: 'Payment Mode',
        data: `Online
        We accept Visa and Mastercard credit and debit cards through our payment gateway. We are integrated with a world-class payment system with the highest global standards for payment security. Your credit card details are processed through our partner bank payment system, and we do not store your credit card information.

        If our bank declines a credit card charge for any reason, it is the responsibility of the customer to coordinate with our Customer Service team to make payment through cash, credit, or debit card.
        
        Offline
        We accept payment through the transfer of funds to our bank account. Customers also have the option of visiting our branches to make payment through cash, credit, or debit card.`
    },
    {
        id:2,
        heading: 'Order Confirmation',
        data : `An online order should be considered confirmed and guaranteed when a Travel Voucher / Travel E-document email is received from us, confirming that your order has been confirmed with the supplier. If you do not receive the Travel Voucher / Travel E-document email, please kindly call our Customer Service team with your booking details.`
    },
    {
        id:3,
        heading: 'Delivery of Products & Services',
        data : `Airline e-tickets are delivered to customers via email. Airline e-tickets contain unique confirmation numbers such as a Passenger Number Record and Ticket Number. Passengers are required by the airline and by the Civil Aviation Authority to show a hard copy of their e-ticket to enter airports in Pakistan and to check in at the airline desk.
        Airlines will give you a boarding pass after checking your e-ticket and mandatory documents such as passport or national identity card.`
    },
    {
        id:4,
        heading: 'Promotion Codes',
        data : `We frequently offer promotional discounts through a voucher system. Faremakers.com members or those customers that have registered on our website to receive special offers will receive promotions, discounts and vouchers that we believe will be of interest to you.
        Please note that vouchers are the property of Faremakers.com and we maintain the right to modify or terminate promotions, discounts, and vouchers without any notice. Any such modifications or terminations will be considered final and will not be disputable.`
    },
    {
        id:5,
        heading: 'Visa Guidelines',
        data : `Please make sure to check directly with the Embassy or Consulate of the country you will be visiting for up to date visa requirements. Visa is mandatory for Pakistan nations for most countries. It is customerâ€™s responsibility to make sure that all travel arrangements have been made and passenger is holding valid travel document and travel arrangements.`
    },
    {
        id:5,
        heading: 'Booking Amendment',
        data : `To make changes/amendments/cancellations to your travel orders, please call our Customer Service team. Please refer to airline fare rules or hotel booking policies while using Faremakers.com.
        Most fares and products will have penalties for changes and cancellations. Penalties associated with changes are collected before confirmation of changes to your booking. Cancellations must be made prior to the departure date.
        Faremakers.com will charge an additional service fee on top of airline fees when making a change. Please not that some tickets may be non-changeable. In that case you ticket becomes "use it or lose it".
        Name Changes are not permitted once a ticket has been issued.`
    }
];


export default TermsConditionsData