export const MetaPageData = [
    // {
    //     url: '/',
    //     title:'Buy Online Air Tickets &amp; Flight Deals On Cheap Rates - Faremakers',
    //     description:'Faremakers is one of the best traveling Platform. Where you can buy online airline tickets and make your traveling plan at home. We provide services 24/7.',
    //     keywords:'online flight booking, book cheap flights, online booking international flights, online air tickets, international domestic flights, affordable airline tickets, online air tickets, hajj umrah packages.'
    // },
    // {
    //     url: '/searchflightresult',
    //     title:'Search Cheap Flight Tickets – Faremakers',
    //     description:'Search and book cheap flight tickets at faremakers.com and get amazing discounted deals.',
    //     keywords:'Cheap flights, cheap flights booking, search cheap flights, online flights booking, airlines tickets rates.'
    // },
    // {
    //     url: '/flightbooking',
    //     title:'Search Cheap Flight Tickets – Faremakers',
    //     description:'Search and book cheap flight tickets at faremakers.com and get amazing discounted deals.',
    //     keywords:'Cheap flights, cheap flights booking, search cheap flights, online flights booking, airlines tickets rates.'
    // },

    {
        "url": "/",
        "title": "Buy Online Air Tickets Flight Deals On Cheap Rates - Faremakers",
        "description": "Faremakers is one of the best traveling Platform. Where you can buy online airline tickets and make your traveling plan at home. We provide services 24/7.",
        "keywords": "online flight booking, book cheap flights, online booking international flights, online air tickets, international domestic flights, affordable airline tickets, online air tickets, hajj umrah packages.",
        "snacks": []
      },
      {
        "url": "/searchflightresult",
        "title": "Search Cheap Flight Tickets – Faremakers",
        "description": "Search and book cheap flight tickets at faremakers.com and get amazing discounted deals.",
        "keywords": "Cheap flights, cheap flights booking, search cheap flights, online flights booking, airlines tickets rates.",
        "snacks": []
      },
      {
        "url": "/flightbooking",
        "title": "Book Flight",
        "description": "",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/bookingpayment",
        "title": "Booking Methodes",
        "description": "",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/contact-us",
        "title": "Contact Us for Air Ticket Booking in Pakistan. View our offices.",
        "description": "Contact Us for doing any kind of flight ticket reservation in Pakistan. Our team is present 24/7 to support you. You can contact any of our offices.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/customer-support",
        "title": "Customer Support",
        "description": "Need Help in Flight Tickets or Having Any Problem. Contact Us. Get Answers of Common Questions Related to Flight Booking.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/terms-of-service",
        "title": "Terms of Service",
        "description": "Need Help in Flight Tickets or Having Any Problem. Contact Us. Get Answers of Common Questions Related to Flight Booking.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/refund-policy",
        "title": "Refund Policy",
        "description": "Need Help in Flight Tickets or Having Any Problem. Contact Us. Get Answers of Common Questions Related to Flight Booking.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/terms-and-conditions",
        "title": "Terms and Condition",
        "description": "Need Help in Flight Tickets or Having Any Problem. Contact Us. Get Answers of Common Questions Related to Flight Booking.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/banks",
        "title": "Bank Accounts",
        "description": "",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/blogs/pia-is-increasing-its-flights-to-saudi-arabia",
        "title": "pia is increasing its flights to saudi arabia",
        "description": "abc",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/lahore-to-london",
        "title": "Cheap Flights From Lahore To London",
        "description": "",
        "keywords": "",
        "snacks": []
      },
      // -------------- Domestic Pages Title -------------------
      {
        "url": "/flights/karachi-to-islamabad",
        "title": "Cheap Flights From Karachi To Islamabad",
        "description": "Book your Karachi to Islamabad flights with Faremakers, Pakistan’s trusted travel agency. Enjoy great fares, secure bookings, and expert support for your journey.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/lahore-to-karachi",
        "title": "Cheap Flights From Lahore To Karachi",
        "description": "Fly from Lahore to Karachi with Faremakers, Pakistan’s reliable travel partner. Find affordable fares, hassle-free bookings, and expert travel assistance.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/lahore-to-peshawar",
        "title": "Cheap Flights From Lahore To Peshawar",
        "description": "Travel from Lahore to Peshawar with Faremakers, Pakistan’s trusted travel agency. Get affordable ticket prices, seamless bookings, and expert support for your trip.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/lahore-to-quetta",
        "title": "Cheap Flights From Lahore To Quetta",
        "description": "Book Lahore to Quetta flights with Faremakers, Pakistan’s reliable travel agency. Enjoy competitive fares, secure bookings, and expert assistance for your journey.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/faisalabad-to-karachi",
        "title": "Cheap Flights From Faisalabad To Karachi",
        "description": "Fly from Faisalabad to Karachi with Faremakers, Pakistan’s trusted travel partner. Get great ticket prices, secure bookings, and expert travel assistance.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/karachi-to-lahore",
        "title": "Cheap Flights From Karachi To Lahore",
        "description": "Travel from Karachi to Lahore with Faremakers, Pakistan’s reliable travel agency. Enjoy affordable fares, seamless bookings, and expert travel support.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/karachi-to-peshawar",
        "title": "Cheap Flights From Karachi To Peshawar",
        "description": "Book Karachi to Peshawar flights with Faremakers, Pakistan’s trusted travel agency. Get affordable fares, secure bookings, and expert support for your journey.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/karachi-to-quetta",
        "title": "Cheap Flights From Karachi To Quetta",
        "description": "Fly from Karachi to Quetta with Faremakers, Pakistan’s reliable travel partner. Find the best ticket prices, secure bookings, and expert travel assistance.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/karachi-to-multan",
        "title": "Cheap Flights From Karachi To Multan",
        "description": "Travel from Karachi to Multan with Faremakers, Pakistan’s trusted travel agency. Enjoy competitive fares, secure bookings, and expert travel support.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/islamabad-to-karachi",
        "title": "Cheap Flights From Islamabad To Karachi",
        "description": "Book Islamabad to Karachi flights with Faremakers, Pakistan’s reliable travel agency. Enjoy affordable fares, hassle-free bookings, and expert travel assistance.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/islamabad-to-quetta",
        "title": "Cheap Flights From Islamabad To Quetta",
        "description": "Fly from Islamabad to Quetta with Faremakers, Pakistan’s trusted travel agency. Get great ticket prices, secure bookings, and expert travel guidance.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/islamabad-to-multan",
        "title": "Cheap Flights From Islamabad To Multan",
        "description": "Travel from Islamabad to Multan with Faremakers, Pakistan’s reliable travel agency. Get affordable fares, secure bookings, and expert travel support.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/quetta-to-islamabad",
        "title": "Cheap Flights From Quetta To Islamabad",
        "description": "Book Quetta to Islamabad flights with Faremakers, Pakistan’s trusted travel agency. Enjoy affordable fares, hassle-free bookings, and expert travel assistance.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/quetta-to-peshawar",
        "title": "Cheap Flights From Quetta To Peshawar",
        "description": "Fly from Quetta to Peshawar with Faremakers, Pakistan’s reliable travel agency. Enjoy competitive fares, secure bookings, and expert support for your journey.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/peshawar-to-karachi",
        "title": "Cheap Flights From Peshawar To Karachi",
        "description": "Book Peshawar to Karachi flights with Faremakers, Pakistan’s trusted travel agency. Get the best ticket prices, secure bookings, and expert travel support.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/peshawar-to-lahore",
        "title": "Cheap Flights From Peshawar To Lahore",
        "description": "Travel from Peshawar to Lahore with Faremakers, Pakistan’s reliable travel agency. Enjoy great fares, hassle-free bookings, and expert travel assistance.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/peshawar-to-quetta",
        "title": "Cheap Flights From Peshawar To Quetta",
        "description": "Fly from Peshawar to Quetta with Faremakers, Pakistan’s trusted travel agency. Get affordable fares, secure bookings, and expert support for your journey.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/peshawar-to-multan",
        "title": "Cheap Flights From Peshawar To Multan",
        "description": "Book Peshawar to Multan flights with Faremakers, Pakistan’s reliable travel partner. Find great fares, secure bookings, and expert travel assistance.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/quetta-to-lahore",
        "title": "Cheap Flights From Quetta To Lahore",
        "description": "Travel from Quetta to Lahore with Faremakers, Pakistan’s trusted travel agency. Enjoy affordable fares, hassle-free bookings, and expert support for your journey.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/quetta-to-multan",
        "title": "Cheap Flights From Quetta To Multan",
        "description": "Book Quetta to Multan flights with Faremakers, Pakistan’s reliable travel agency. Get great ticket prices, seamless bookings, and expert travel support.",
        "keywords": "",
        "snacks": []
      },

      // --------------International Pages Title -------------------
        {
        "url": "/flights/lahore-to-london",
        "title": "Cheap Flights from Lahore to London",
        "description":"Book 122Lahore to London flights with Faremakers, Pakistan's trusted travel agency. Get the best ticket prices, secure bookings, and expert support for your journey.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/lahore-to-dubai",
        "title": "Cheap Flights from Lahore to Dubai",
        "description": "Fly from Lahore to Dubai with Faremakers, Pakistan's trusted travel agency. Enjoy affordable fares, secure bookings, and expert support for a seamless trip.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/lahore-to-toronto",
        "title": "Cheap Flights from Lahore to Toronto",
        "description": "Book Lahore to Toronto flights with Faremakers, Pakistan’s reliable travel agency. Get the best ticket prices, secure bookings, and expert support for your journey",
        "keywords": "",
        "snacks": []
      },
      {
          "url": "/flights/lahore-to-newyork",
          "title": "Cheap Flights from Lahore (LHE) to New York (NYC)",
          "description": "Fly from Lahore to New York with Faremakers, Pakistan's trusted travel agency. Enjoy competitive fares, secure bookings, and expert guidance for your trip.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/lahore-to-boston",
          "title": "Cheap Flight Deals from Lahore to Boston (LHE-BOS)",
          "description": "Book Lahore to Boston flights with Faremakers, Pakistan’s reliable travel partner. Find affordable fares, seamless bookings, and expert support for your travel needs.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/lahore-to-perth",
          "title": "Cheap Flights from Lahore to Perth (LHE-PER)",
          "description": "Travel from Lahore to Perth with Faremakers, Pakistan's favorite travel agency. Enjoy low fares, secure online bookings, and excellent customer support.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/lahore-to-manchester",
          "title": "Find Cheap Flights from Lahore to Manchester",
          "description": "Fly from Lahore to Manchester with Faremakers, Pakistan’s trusted travel service. Book at the best prices with hassle-free bookings and reliable support.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/lahore-to-melbourne",
          "title": "Cheap Flights from Lahore (LHE) to Melbourne (MEL)",
          "description": "Plan your journey from Lahore to Melbourne with Faremakers, Pakistan’s leading travel agency. Get affordable tickets, secure bookings, and expert assistance.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/lahore-to-atlanta",
          "title": "Cheap Flight Deals from Lahore to Atlanta (LHE-ATL)",
          "description": "Book Lahore to Atlanta flights with Faremakers, Pakistan's reliable travel partner. Enjoy the best prices, easy bookings, and expert travel guidance.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/lahore-to-doha",
          "title": "Cheap Flight Deals from Lahore to Doha (LHE-DOH)",
          "description": "Fly from Lahore to Doha with Faremakers, Pakistan's trusted travel agency. Enjoy affordable fares, secure bookings, and top-notch customer support for your trip.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/lahore-to-sydney",
          "title": "Cheap Flights from Lahore (LHE) to Sydney (SYD)",
          "description": "Book Lahore to Sydney flights with Faremakers, Pakistan’s reliable travel partner. Find the best ticket prices, secure bookings, and expert travel assistance.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/peshawar-to-london",
          "title": "Cheap Flights From Peshawar to London (PEW-LON)",
          "description": "Fly from Peshawar to London with Faremakers, Pakistan's trusted travel agency. Enjoy competitive fares, secure bookings, and reliable support for your journey.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/peshawar-to-dubai",
          "title": "Cheap Flight Deals from Peshawar to Dubai (PEW-DXB)",
          "description": "Book Peshawar to Dubai flights with Faremakers, Pakistan’s reliable travel partner. Get affordable fares, seamless bookings, and expert travel assistance. ",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/peshawar-to-toronto",
          "title": "Cheap Flight Deals from Peshawar to Toronto (PEW-YTZ)",
          "description": "Travel from Peshawar to Toronto with Faremakers, Pakistan's favorite travel agency. Find the best ticket prices, secure bookings, and expert support.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/peshawar-to-newyork",
          "title": "Cheap Flights from Peshawar to New York (PEW-JFK)",
          "description": "Book Peshawar to New York flights with Faremakers, Pakistan’s trusted travel service. Enjoy great fares, hassle-free bookings, and expert travel guidance.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/peshawar-to-boston",
          "title": "Cheap Flight Deals from peshawar-to-boston (PEW-BOS)",
          "description": "Plan your journey from Peshawar to Boston with Faremakers, Pakistan’s reliable travel partner. Get affordable tickets, secure bookings, and excellent assistance.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/peshawar-to-perth",
          "title": "Cheap Flights from Peshawar (PEW) to Perth (PER)",
          "description": "Fly from Peshawar to Perth with Faremakers, Pakistan’s trusted travel agency. Enjoy low fares, secure bookings, and expert support for a smooth trip.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/peshawar-to-manchester",
          "title": "Flights from Peshawar (PEW) to Manchester (MAN)",
          "description": "Book Peshawar to Manchester flights with Faremakers, Pakistan's trusted travel agency. Find competitive fares, secure online bookings, and expert assistance.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/peshawar-to-melbourne",
          "title": "Cheap Flights from Peshawar to Melbourne",
          "description": "Fly from Peshawar to Melbourne with Faremakers, Pakistan's trusted travel agency. Enjoy affordable fares, secure bookings, and expert travel support.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/peshawar-to-atlanta",
          "title": "Find Cheap Flights from Peshawar to Atlanta",
          "description": "Book Peshawar to Atlanta flights with Faremakers, Pakistan’s reliable travel partner. Find the best ticket prices, seamless bookings, and expert guidance.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/peshawar-to-doha",
          "title": "Peshawar to Doha - Cheap Flights",
          "description": "Travel from Peshawar to Doha with Faremakers, Pakistan's favorite travel agency. Get low fares, secure online bookings, and excellent customer service.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/peshawar-to-losangeles",
          "title": "Cheap Flights from Peshawar to Los Angeles (PEW-LAX)",
          "description": "Book Peshawar to Los Angeles flights with Faremakers, Pakistan’s trusted travel agency. Enjoy competitive fares, hassle-free bookings, and reliable assistance.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/peshawar-to-jeddah",
          "title": "Cheap Flight Deals from Peshawar to Jeddah (PEW-JED)",
          "description": "Fly from Peshawar to Jeddah with Faremakers, Pakistan’s reliable travel partner. Find affordable fares, secure bookings, and expert travel support for your trip.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/peshawar-to-istanbul",
          "title": "Peshawar to Istanbul - Cheap Flights",
          "description": "Plan your journey from Peshawar to Istanbul with Faremakers, Pakistan’s leading travel agency. Get great ticket prices, secure bookings, and expert assistance.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/lahore-to-abuDhabi",
          "title": "Cheap Flights from Lahore to Abu Dhabi - Faremakers",
          "description": "Discover the best deals on cheap flights from Lahore to Abu Dhabi with Faremakers. Book your tickets now for an unforgettable travel experience!",
          "keywords": "",
          "snacks": []
        },

        // -----------------------
        {
          "url": "/flights/faisalabad-to-losAngeles",
          "title": "Affordable Flights from Faisalabad to Los Angeles",
          "description": "Book Faisalabad to Los Angeles flights with Faremakers, Pakistan’s trusted travel agency. Enjoy affordable fares, secure bookings, and expert travel support.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/faisalabad-to-jeddah",
          "title": "Best Deals on Tickets from Faisalabad to Jeddah",
          "description": "Fly from Faisalabad to Jeddah with Faremakers, Pakistan’s reliable travel partner. Get the best ticket prices, hassle-free bookings, and expert assistance for your journey.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/faisalabad-to-istanbul",
          "title": "Book Cheap Flights from Faisalabad to Istanbul",
          "description": "Travel from Faisalabad to Istanbul with Faremakers, Pakistan's favorite travel agency. Find competitive fares, secure bookings, and reliable travel guidance.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/karachi-to-london",
          "title": "Cheap Airline Tickets from Karachi to London",
          "description": "Fly from Karachi to London with Faremakers, Pakistan’s trusted travel agency. Enjoy great fares, secure bookings, and expert travel support for a seamless journey.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/karachi-to-dubai",
          "title": "Budget Flights from Karachi to Dubai",
          "description": "Book Karachi to Dubai flights with Faremakers, Pakistan’s reliable travel partner. Get competitive prices, hassle-free bookings, and excellent customer service.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/karachi-to-toronto",
          "title": "Low-Cost Tickets from Karachi to Toronto",
          "description": "Plan your journey from Karachi to Toronto with Faremakers, Pakistan’s trusted travel agency. Enjoy affordable fares, easy bookings, and expert travel assistance.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/karachi-to-newyork",
          "title": "Discounted Flights from Karachi to New York",
          "description": "Book Karachi to New York flights with Faremakers, Pakistan’s reliable travel agency. Get the best ticket prices, secure bookings, and expert travel guidance.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/karachi-to-boston",
          "title": "Special Offers on Flights from Karachi to Boston",
          "description": "Fly from Karachi to Boston with Faremakers, Pakistan’s trusted travel agency. Find affordable fares, secure bookings, and expert assistance for a smooth trip.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/karachi-to-perth",
          "title": "Cheap Airfare from Karachi to Perth",
          "description": "Travel from Karachi to Perth with Faremakers, Pakistan’s reliable travel partner. Enjoy great fares, secure bookings, and expert support for your journey.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/karachi-to-manchester",
          "title": "Best Flight Deals from Karachi to Manchester",
          "description": "Book Karachi to Manchester flights with Faremakers, Pakistan’s trusted travel agency. Enjoy competitive fares, hassle-free bookings, and expert customer support.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/karachi-to-melbourne",
          "title": "Low Fares from Karachi to Melbourne",
          "description": "Fly from Karachi to Melbourne with Faremakers, Pakistan’s reliable travel agency. Get affordable ticket prices, secure bookings, and expert travel assistance.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/karachi-to-atlanta",
          "title": "Affordable Tickets from Karachi to Atlanta",
          "description": "Book Karachi to Atlanta flights with Faremakers, Pakistan’s trusted travel agency. Enjoy the best fares, secure bookings, and expert support for a smooth trip.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/karachi-to-doha",
          "title": "Cheap Flights from Karachi to Doha",
          "description": "Plan your trip from Karachi to Doha with Faremakers, Pakistan’s reliable travel partner. Get competitive prices, secure bookings, and expert guidance for your journey.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/karachi-to-losangeles",
          "title": "Best Price Flights from Karachi to Los Angeles",
          "description": "Fly from Karachi to Los Angeles with Faremakers, Pakistan’s trusted travel agency. Enjoy affordable fares, secure bookings, and expert travel support for your trip.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/karachi-to-jeddah",
          "title": "Cheap Plane Tickets from Karachi to Jeddah",
          "description": "Book Karachi to Jeddah flights with Faremakers, Pakistan’s reliable travel agency. Get great fares, seamless bookings, and expert support for your journey.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/karachi-to-istanbul",
          "title": "Discount Flights from Karachi to Istanbul",
          "description": "Travel from Karachi to Istanbul with Faremakers, Pakistan’s trusted travel agency. Enjoy the best ticket prices, secure bookings, and expert assistance for your trip.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/quetta-to-london",
          "title": "Budget Travel from Quetta to London",
          "description": "Fly from Quetta to London with Faremakers, Pakistan’s trusted travel agency. Enjoy competitive fares, secure bookings, and expert travel support for a seamless journey.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/quetta-to-dubai",
          "title": "Low-Cost Airfare from Quetta to Dubai",
          "description": "Book Quetta to Dubai flights with Faremakers, Pakistan’s reliable travel partner. Get affordable fares, hassle-free bookings, and expert travel guidance for your trip.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/quetta-to-toronto",
          "title": "Cheap Tickets from Quetta to Toronto",
          "description": "Travel from Quetta to Toronto with Faremakers, Pakistan’s trusted travel agency. Find great ticket prices, secure bookings, and reliable travel assistance for your journey.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/quetta-to-newyork",
          "title": "Special Airfare Deals from Quetta to New York",
          "description": "Book Quetta to New York flights with Faremakers, Pakistan’s reliable travel partner. Enjoy great fares, secure bookings, and expert support for a smooth trip.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/quetta-to-boston",
          "title": "Affordable Flights from Quetta to Boston",
          "description": "Fly from Quetta to Boston with Faremakers, Pakistan’s trusted travel agency. Get affordable ticket prices, seamless bookings, and expert travel assistance.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/quetta-to-perth",
          "title": "Discount Air Tickets from Quetta to Perth",
          "description": "Plan your trip from Quetta to Perth with Faremakers, Pakistan’s reliable travel agency. Enjoy competitive fares, secure bookings, and expert travel support.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/quetta-to-manchester",
          "title": "Best Flight Deals from Quetta to Manchester",
          "description": "Book Quetta to Manchester flights with Faremakers, Pakistan’s trusted travel partner. Get the best fares, secure bookings, and expert support for your journey.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/quetta-to-melbourne",
          "title": "Cheap Flights from Quetta to Melbourne",
          "description": "Travel from Quetta to Melbourne with Faremakers, Pakistan’s reliable travel agency. Enjoy affordable fares, secure bookings, and expert guidance for a smooth trip.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/quetta-to-atlanta",
          "title": "Best Airfare Deals from Quetta to Atlanta",
          "description": "Fly from Quetta to Atlanta with Faremakers, Pakistan’s trusted travel agency. Get the best ticket prices, secure bookings, and expert support for your travel needs.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/quetta-to-doha",
          "title": "Affordable Travel from Quetta to Doha",
          "description": "Book Quetta to Doha flights with Faremakers, Pakistan’s reliable travel agency. Enjoy great fares, secure bookings, and expert travel assistance for your journey.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/quetta-to-losangeles",
          "title": "Low-Cost Flights from Quetta to Los Angeles",
          "description": "Travel from Quetta to Los Angeles with Faremakers, Pakistan’s trusted travel partner. Enjoy competitive prices, secure bookings, and expert support for your trip.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/quetta-to-jeddah",
          "title": "Cheap Tickets from Quetta to Jeddah",
          "description": "Fly from Quetta to Jeddah with Faremakers, Pakistan’s trusted travel agency. Enjoy affordable fares, secure bookings, and expert travel assistance for your trip.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/quetta-to-istanbul",
          "title": "Discount Flights from Quetta to Istanbul",
          "description": "Book Quetta to Istanbul flights with Faremakers, Pakistan’s reliable travel partner. Get great ticket prices, seamless bookings, and expert travel guidance.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/islamabad-to-london",
          "title": "Book Cheap Tickets from Islamabad to London",
          "description": "Fly from Islamabad to London with Faremakers, Pakistan’s trusted travel agency. Enjoy affordable fares, secure bookings, and expert support for a smooth journey.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/islamabad-to-dubai",
          "title": "Low-Cost Flights from Islamabad to Dubai",
          "description": "Book Islamabad to Dubai flights with Faremakers, Pakistan’s reliable travel partner. Find competitive prices, easy bookings, and expert guidance for your trip.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/islamabad-to-toronto",
          "title": "Cheap Travel from Islamabad to Toronto",
          "description": "Travel from Islamabad to Toronto with Faremakers, Pakistan’s trusted travel agency. Get great ticket prices, secure bookings, and expert travel assistance.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/islamabad-to-newyork",
          "title": "Best Ticket Prices from Islamabad to New York",
          "description": "Book Islamabad to New York flights with Faremakers, Pakistan’s reliable travel agency. Enjoy the best fares, secure bookings, and expert support for your journey.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/islamabad-to-boston",
          "title": "Special Deals on Flights from Islamabad to Boston",
          "description": "Fly from Islamabad to Boston with Faremakers, Pakistan’s trusted travel agency. Get affordable fares, hassle-free bookings, and expert travel support.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/islamabad-to-perth",
          "title": "Cheap Airfare from Islamabad to Perth",
          "description": "Plan your journey from Islamabad to Perth with Faremakers, Pakistan’s reliable travel agency. Find the best ticket prices, secure bookings, and expert assistance.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/islamabad-to-manchester",
          "title": "Low-Cost Airline Tickets from Islamabad to Manchester",
          "description": "Book Islamabad to Manchester flights with Faremakers, Pakistan’s trusted travel agency. Enjoy great fares, seamless bookings, and expert travel support.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/islamabad-to-melbourne",
          "title": "Budget Flights from Islamabad to Melbourne",
          "description": "Fly from Islamabad to Melbourne with Faremakers, Pakistan’s reliable travel partner. Get affordable fares, secure bookings, and expert travel assistance.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/islamabad-to-atlanta",
          "title": "Discount Tickets from Islamabad to Atlanta",
          "description": "Book Islamabad to Atlanta flights with Faremakers, Pakistan’s trusted travel agency. Enjoy affordable ticket prices, secure bookings, and expert travel guidance.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/islamabad-to-doha",
          "title": "Affordable Flights from Islamabad to Doha",
          "description": "Travel from Islamabad to Doha with Faremakers, Pakistan’s reliable travel agency. Get great fares, hassle-free bookings, and expert support for your journey.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/islamabad-to-losangeles",
          "title": "Cheap Airfare from Islamabad to Los Angeles",
          "description": "Fly from Islamabad to Los Angeles with Faremakers, Pakistan’s trusted travel partner. Enjoy competitive fares, secure bookings, and expert travel assistance.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/islamabad-to-jeddah",
          "title": "Low-Cost Travel from Islamabad to Jeddah",
          "description": "Book Islamabad to Jeddah flights with Faremakers, Pakistan’s reliable travel agency. Get the best ticket prices, easy bookings, and expert guidance for your journey.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/islamabad-to-istanbul",
          "title": "Best Flight Deals from Islamabad to Istanbul",
          "description": "Travel from Islamabad to Istanbul with Faremakers, Pakistan’s trusted travel agency. Enjoy affordable fares, secure bookings, and expert travel support.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/multan-to-london",
          "title": "Cheap Flights from Multan to London",
          "description": "Fly from Multan to London with Faremakers, Pakistan’s trusted travel agency. Enjoy competitive fares, secure bookings, and expert support for a smooth journey.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/multan-to-dubai",
          "title": "Best Airfare Deals from Multan to Dubai",
          "description": "Book Multan to Dubai flights with Faremakers, Pakistan’s reliable travel partner. Find affordable fares, seamless bookings, and expert travel assistance.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/multan-to-toronto",
          "title": "Discounted Tickets from Multan to Toronto",
          "description": "Travel from Multan to Toronto with Faremakers, Pakistan’s trusted travel agency. Get great ticket prices, secure bookings, and expert travel support.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/multan-to-newyork",
          "title": "Affordable Travel from Multan to New York",
          "description": "Book Multan to New York flights with Faremakers, Pakistan’s reliable travel agency. Enjoy competitive prices, secure bookings, and expert assistance for your trip.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/multan-to-boston",
          "title": "Cheap Airline Tickets from Multan to Boston",
          "description": "Fly from Multan to Boston with Faremakers, Pakistan’s trusted travel agency. Get affordable ticket prices, secure bookings, and expert travel guidance.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/multan-to-perth",
          "title": "Best Price Flights from Multan to Perth",
          "description": "Plan your journey from Multan to Perth with Faremakers, Pakistan’s reliable travel agency. Enjoy great fares, secure bookings, and expert assistance for your trip.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/multan-to-manchester",
          "title": "Budget Flights from Multan to Manchester",
          "description": "Book Multan to Manchester flights with Faremakers, Pakistan’s trusted travel agency. Get the best fares, secure bookings, and expert support for your journey.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/multan-to-melbourne",
          "title": "Cheap Plane Tickets from Multan to Melbourne",
          "description": "Fly from Multan to Melbourne with Faremakers, Pakistan’s reliable travel partner. Get affordable fares, secure bookings, and expert travel assistance.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/multan-to-atlanta",
          "title": "Special Deals on Flights from Multan to Atlanta",
          "description": "Book Multan to Atlanta flights with Faremakers, Pakistan’s trusted travel agency. Enjoy competitive fares, secure bookings, and expert travel support.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/multan-to-doha",
          "title": "Low-Cost Airfare from Multan to Doha",
          "description": "Travel from Multan to Doha with Faremakers, Pakistan’s reliable travel partner. Get affordable fares, secure bookings, and expert assistance for your trip.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/multan-to-losangeles",
          "title": "Discount Flights from Multan to Los Angeles",
          "description": "Fly from Multan to Los Angeles with Faremakers, Pakistan’s trusted travel agency. Enjoy great fares, secure bookings, and expert support for a smooth journey.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/multan-to-jeddah",
          "title": "Affordable Flights from Multan to Jeddah",
          "description": "Book Multan to Jeddah flights with Faremakers, Pakistan’s reliable travel agency. Get affordable fares, seamless bookings, and expert support for your journey.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/multan-to-istanbul",
          "title": "Best Airfare from Multan to Istanbul",
          "description": "Travel from Multan to Istanbul with Faremakers, Pakistan’s trusted travel agency. Find great fares, secure bookings, and expert travel support for your trip.",
          "keywords": "",
          "snacks": []
        }
]