export const airsialTravelerDetail = async (formData,activepnrNumber) => {
// const cnic = formData.CNIC;
  console.log("formData123",formData);
  console.log("current pnr",activepnrNumber);
    const PNRNumber = activepnrNumber;
    const Authtoken = JSON.parse(localStorage.getItem("airsialAuthToken"));
    const flightDetails = JSON.parse(localStorage.getItem("bookingTicket"));
    const { adults, children } = flightDetails;
    // const FORMDATES = JSON.parse(localStorage.getItem("formData"));
    // console.log("local",formData);
// ----------------------------------------------
    const adultsData = [];
    const childrenData = [];
    const infantsData = [];

    const CnicFormat = (cnic)=>{
      if(cnic && cnic.length ===13){
        return `${cnic.slice(0, 5)}-${cnic.slice(5, 12)}-${cnic.slice(12)}`;
      } else{
        return cnic;
      }

    }

    const formatDate = (dateString) =>{
      const date = new Date(dateString);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;
  }

   Object.keys(formData).forEach((key, index) => {
    const data = formData[key];
    const category = index < adults ? 'adult' : index < adults + children ? 'child' : 'infant';

    const formattedData = {
        Title: "MR",
        WheelChair: null,
        FullName: `${data.firstName} ${data.lastName}`,
        Firstname: `${data.firstName}`,
        Lastname: `${data.lastName}`,
        Passport: `${data.passportNo}`,
        PassportCountry: 'PK', // You need to specify the country
        PassportExpiry: formatDate(data.passportExpiryDate),
        Dob: formatDate(data.dateOfBirth),
        // PassportExpiry:"02-07-2026",
        // Dob:"02-07-2008",
        // Cnic:`${CnicFormat(data.cnic)}`,
        Cnic: data.cnic ? CnicFormat(data.cnic) : null,
        Gender: `${data.gender}`,
    };

    if (category === 'adult') {
        adultsData.push(formattedData);
    } else if (category === 'child') {
        childrenData.push(formattedData);
    } else {
        infantsData.push(formattedData);
    }
});

console.log("Adults Data:", adultsData);
console.log("child Data:", childrenData);
console.log("infants Data:", infantsData);


    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "ci_session_frontend=f8oe9fve3otb0io7br1o2de14dco34nt");

    var raw = JSON.stringify([
        {
            "Caller": "passengerInsertion",
            "PNR": PNRNumber,
            "token": Authtoken,
            "adult": adultsData,
            "child": childrenData,
            "infant": infantsData,
            "PrimaryCell": "+923137559954",
            "SecondaryCell": "+92",
            "EmailAddress": "test@test.com",
            "CNIC": "",
            "Comments": ""
        }
    ]);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    try{

        const responce = await fetch("http://demo.airsial.com.pk/starter/asmis/booking", requestOptions);
        const result = await responce.json();
        console.log("airsial API Result",result);
        return result;
    }
    catch (error){
        console.error("Airsial Traveller Details", error);
    }
} 