import React from "react";

const EmiratesFlights = () => {
    return (
      <div>
        <div className="horizontal-line"></div>

        <div className="mainflights">
          <h3 className="airline_heading">
            Emirates (EK) Online Booking & Reservation
          </h3>
          <div className="horizontal-line"></div>
          <h3 className="text1">Book Cheap Emirates Flights with Faremakers</h3>
          <p>
            Are you looking for the best price on Emirates flights? Look no
            further! At Faremakers, we offer the best Emirates flight deals,
            ensuring you get the cheapest flights without compromising on
            quality
          </p>
          <div className="horizontal-line"></div>

          <h3 className="text1">Why Choose Emirates for Your Next Trip?</h3>
          <div className="horizontal-line"></div>

          <p>
            Emirates is renowned for its exceptional service, modern fleet, and
            extensive global network. Whether you're planning a holiday,
            business trip, or a family visit, Emirates offers unparalleled
            comfort and convenience. Here are some reasons to book your next
            flight with Emirates:
          </p>
          <p className="airlines_list_styling">
            <ul>
              <li>
                <span className="airline_span_heading_v2">
                  Award-Winning Service
                </span>
                : Experience world-class service from the moment you board.
              </li>
              <li>
                <span className="airline_span_heading_v2">
                  Comfortable Seating
                </span>
                : Enjoy spacious seating and excellent in-flight entertainment.
              </li>
              <li>
                <span className="airline_span_heading_v2">
                  Extensive Network
                </span>
                : Fly to over 150 destinations worldwide with seamless
                connections.
              </li>
              <li>
                <span className="airline_span_heading_v2">
                  Best Emirates Flight Deals
                </span>
                : Take advantage of our exclusive offers and promotions to get
                the best price on Emirates flights.
              </li>
            </ul>
          </p>
          <div className="horizontal-line"></div>

          <h3 className="text1">Emirates Mission and Popular Destinations</h3>
          <div className="horizontal-line"></div>
          <h4 className="airlines_subHeading">Mission: </h4>
          <p>
            Emirates aims to connect people and cultures through safe, reliable,
            and innovative air travel, delivering a world-class experience to
            passengers.
          </p>
          <h4 className="airlines_subHeading">
            Popular Destinations from Lahore:{" "}
          </h4>
          <p>
            Fly from Lahore to popular destinations such as Dubai, London, New
            York, Sydney, and many more. Emirates offers direct and connecting
            flights to major cities across the globe, making your travel
            experience seamless and enjoyable.
          </p>
          <div className="horizontal-line"></div>

          <h3 className="text1">How to Book Cheap Emirates Air Tickets</h3>
          <div className="horizontal-line"></div>
          <p>
            Booking cheap Emirates air tickets is easy with Faremakers. Follow
            these simple steps to secure your reservation:
          </p>

          <ol className="order_list_styling">
            <li>
              <span className="airline_span_heading_v2">Visit Our Website</span>
              : Go to <a href="http://www.Faremakers.com" target="_blank" className="href_link_typo">
                    www.Faremakers.com
                  </a> and select the 'Flights' tab.
            </li>
            <li>
              <span className="airline_span_heading_v2">
                Enter Your Details
              </span>
              : Fill in your departure and arrival cities, travel dates, and the
              number of passengers.
            </li>
            <li>
              <span className="airline_span_heading_v2">
                Search for Flights
              </span>
              : Click on the 'Search Flights' button to view available options.
            </li>
            <li>
              <span className="airline_span_heading_v2">
                Choose Your Flight
              </span>{" "}
              : Compare the available flights and select the one that suits you
              best.
            </li>
            <li>
              <span className="airline_span_heading_v2">
                Complete the Booking
              </span>
              : Enter your details, choose your preferred payment method, and
              confirm your booking.
            </li>
          </ol>
          <div className="horizontal-line"></div>

          <h3 className="text1">
            Top Emirates Flights to Pakistan (According to Faremakers.com)
          </h3>
          <ol className="order_list_styling">
            <li>Emirates Airlines Jeddah to Islamabad</li>
            <li>Emirates Airlines Dubai to Lahore</li>
            <li>Emirates Airlines Riyadh to Islamabad </li>
            <li>Emirates Airlines Jeddah to Karachi</li>
            <li>Emirates Airlines Jeddah to Islamabad</li>
          </ol>
          <div>
            <table className="table table-bordered table_design">
              <thead>
                <tr className="airline_table_background">
                  <th>Airline</th>
                  <th>Origin</th>
                  <th>Destination</th>
                  <th>Departure Time (PM)</th>
                  <th>Flight Duration (hrs)</th>
                  <th>Price (PKR)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Emirates Airlines</td>
                  <td>Jeddah</td>
                  <td>Islamabad</td>
                  <td>8.05</td>
                  <td>27.10</td>
                  <td>178165</td>
                </tr>
                <tr>
                  <td>Emirates Airlines</td>
                  <td>Dubai</td>
                  <td>Lahore</td>
                  <td>9.50</td>
                  <td>3.00</td>
                  <td>108720</td>
                </tr>
                <tr>
                  <td>Emirates Airlines</td>
                  <td>Riyadh</td>
                  <td>Islamabad</td>
                  <td>9.40</td>
                  <td>19.50</td>
                  <td>229439</td>
                </tr>
                <tr>
                  <td>Emirates Airlines</td>
                  <td>Jeddah</td>
                  <td>Karachi</td>
                  <td>4.10</td>
                  <td>16.75</td>
                  <td>97613</td>
                </tr>
                <tr>
                  <td>Emirates Airlines</td>
                  <td>Jeddah</td>
                  <td>Islamabad</td>
                  <td>10.45</td>
                  <td>None</td>
                  <td>198406</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            <span className="airline_span_heading_v2">Please note</span>: These
            fares are estimates and may vary depending on travel dates, cabin
            class, and availability.
          </p>
          <div className="horizontal-line"></div>
          <h3>
            About
            <span className="airline_logoColor_head">
              Emirates Airline Flights:
            </span>
          </h3>
          <p>
            Emirates, the flag carrier of Dubai, is a global airline renowned
            for its luxurious amenities and exceptional service. With a modern
            fleet of aircraft and an extensive network spanning over 150
            destinations, Emirates offers a seamless and comfortable travel
            experience for passengers worldwide.
          </p>
          <div className="horizontal-line"></div>

          <h3 className="text1">Emirates Luggage Policy</h3>
          <div className="horizontal-line"></div>

          <p>
            According to emirate’s baggage policy, Economy Class customers can
            check in up to 30kg, First Class passengers carry up to 40kg. For
            getting further detail you can call us our toll-free number which
            given above and know more about the baggage and flights.
          </p>
          <div className="horizontal-line"></div>
          <h3 className="text1">
            Tips for Finding the Best Deals on Emirates Flights
          </h3>
          <p className="airlines_list_styling">
            <ul>
              <li>
                <span className="airline_span_heading_v2">Book in Advance</span>
                : Secure the best Emirates flight deals by booking your flight
                several weeks ahead.
              </li>
              <li>
                <span className="airline_span_heading_v2">Be Flexible</span>
                :Flexible travel dates can help you find cheaper options and the
                best price Emirates flights.
              </li>
              <li>
                <span className="airline_span_heading_v2">
                  Check for Promotions
                </span>
                :Keep an eye out for special promotions and discounts on
                Emirates flights.
              </li>
              <li>
                <span className="airline_span_heading_v2">Use Faremakers</span>:
                Our platform offers exclusive deals and discounts on Emirates
                bookings to help you find the best Emirates fares.
              </li>
            </ul>
          </p>
          <div className="horizontal-line"></div>
          <h3 className="text1">Emirates Frequent Flyer Program</h3>
          <p>
          Join the Emirates Skywards program to earn miles on every flight. Redeem your miles for flight upgrades, hotel stays, and more. As a Skywards member, you'll enjoy a range of benefits, including priority boarding, additional baggage allowance, and access to exclusive lounges.
          </p>
          <div className="horizontal-line"></div>
          <h3 className="text1">Contact Us</h3>
          <p>
          Need assistance with your Emirates booking? Our customer support team is here to help. Contact us via phone, email, or live chat for any queries or concerns.
          </p>
          <p className="airlines_list_styling">
            <ul>
                <li> 
                    <p className="airline_body_color"><span className="airline_span_heading_v2">Phone</span>: 0311-1147-111</p>
                </li>
                <li>
                    <span className="airline_span_heading_v2">
                      Email  
                    </span>
                    <a href="mailto:support@faremakers.com" className="airline_body_color"> : support@faremakers.com</a>
                </li>
                <li>
                    <p className="airline_body_color" ><span className="airline_span_heading_v2">Head Office</span>: 53-A Street 1, Block E1, Block E1, Gulberg III, Lahore, Punjab</p>
                </li>
            </ul>
          </p>
          <div className="horizontal-line"></div>
          <h3 className="airline_heading">
                FAQs about Emirates Booking
          </h3>
          <div className="horizontal-line"></div>
          <div className="faq-item">
              <p className="faq-question">
              How do I book a flight with Emirates? 
              </p>
              <p className="faq-answer">
              You can book a flight with Emirates through our website, Faremakers.com. Simply enter your travel details, search for flights, and choose the one that suits you best to get the best price on Emirates flights.
              </p>
           </div>
            <div className="horizontal-line"></div>
            <div className="faq-item">
              <p className="faq-question">
              Can I change my Emirates flight after booking? 
              </p>
              <p className="faq-answer">
              Yes, you can change your Emirates flight after booking. Visit the Emirates website or contact their customer service for assistance with changing your flight.
              </p>
            </div>
            <div className="horizontal-line"></div>
            <div className="faq-item">
              <p className="faq-question">
              What is the baggage allowance for Emirates flights? 
              </p>
              <p className="faq-answer">
              Emirates offers a generous baggage allowance, which varies depending on the class of travel. For detailed information, visit the Emirates baggage policy page.
              </p>
            </div>
            <div className="horizontal-line"></div>
            <div className="faq-item">
              <p className="faq-question">
              How can I check my Emirates flight status? 
              </p>
              <p className="faq-answer">
              You can check your Emirates flight status on the Emirates website by entering your flight number and departure date.
              </p>
            </div>
            <div className="horizontal-line"></div>
            <div className="faq-item">
              <p className="faq-question">
              Are there any special offers for Emirates flights? 
              </p>
              <p className="faq-answer">
              Yes, Emirates frequently offers special promotions and discounts. Check the Emirates website or Faremakers for the latest deals on Emirates flights to get the best Emirates flight deals.
              </p>
            </div>
            <div className="horizontal-line"></div>

        </div>
      </div>
    );
}
    export default EmiratesFlights;