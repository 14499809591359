const AirLinesDetails = [
 {
    id : 1,
    airlinename: 'Emirates Airline',

 },
 {
    id :2,
    airlinename : ' Qatar Airways ',
 },
 {
    id : 3,
    airlinename : 'Turkish Airlines',
 },
 {
    id : 4,
    airlinename : 'PIA',
 },
 {
    id : 5,
    airlinename : 'Oman Air',
 },
 {
    id : 6,
    airlinename : 'Fly Dubai',
 },
 {
    id : 1,
    airlinename : 'Etihad Airways',
 },
 {
    id : 7,
    airlinename : 'Shaheen Air',
 },
 {
    id : 1,
    airlinename : 'Air Blue',
 },
 {
    id : 8,
    airlinename : 'Thai Airways',
 },
 {
    id : 9,
    airlinename : 'Serene Air',
 },
 {
    id : 10,
    airlinename : 'Kuwait Airways',
 },
 {
    id : 11,
    airlinename : 'China Southern',
 },
 {
    id : 12,
    airlinename : 'Saudi Arabian',
 },
 {
    id : 13,
    airlinename : 'Air Arabia',
 },
 {
    id : 14,
    airlinename : 'Air China',
 },
 {
    id : 15,
    airlinename : 'Air Malindo',
 },
 {
    id : 16,
    airlinename : 'British Airways',
 },
 {
    id : 17,
    airlinename : 'Air Sial',
 },
 {
    id : 18,
    airlinename : 'Virgin Antlantic',
 }

 
]

export default AirLinesDetails;